import React, { useEffect } from 'react';
import HomepageHeader from '../../componentsHomePage/HomepageHeader/HomepageHeader';
import HomepageFooter from '../../componentsHomePage/HomepageFooter/HomepageFooter';
import './TermsOfUse.css';

function TermsOfUse() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <HomepageHeader />
        <div className="terms-of-use-content">
        <h1>Terms of Use of DeepLayered</h1>
        <p>Last Updated: October 28, 2024</p>
        <ol type="I">
            <li>
            <strong>Changes to Terms of Use.</strong> We reserve the right at all times to discontinue or modify any of these terms and/or our Privacy Policy as we deem necessary or desirable. Such changes may include, among other things, the adding of certain fees or charges. We may, but are not necessarily obligated to notify you via email of any substantial changes, by sending you an email to the email address that is registered with your account and/or by posting notice of the change on the Website. Any changes to these Terms of Use will be effective upon the earlier of our dispatch of an email notice to you or our posting of notice of the changes on our Website. We suggest you, therefore, reread this section of our Website from time to time to stay informed of any such changes. Use of the Website by you after such notice shall be deemed to constitute acceptance by you of such modifications.
            </li>
            <li>
            <strong>Conditions to Use the Website.</strong> Your permission to use the Website is conditioned upon your agreement that you:
            <ul>
                <li>Will comply with these Terms of Use;</li>
                <li>Are at least 13 years old;</li>
                <li>Refrain from any of the acts listed in the Prohibited Conduct section of these Terms of Use;</li>
                <li>Will not copy or distribute any part of the Website in any manner without our prior written consent;</li>
                <li>Will provide accurate information when creating your account or registering for the website;</li>
                <li>Will not use the Website for any commercial purposes other than the specific commercial activities contained on the Website and will not use it to obtain data or personal information about any other users or to solicit other users or advertisers;</li>
                <li>Are solely responsible for any content provided by you on the Website, including but not limited to any discussion posts, profile information, links and pictures including the ownership of any licenses or rights necessary to use the work of others and will not submit any copyrighted materials or work subject to other’s proprietary rights;</li>
                <li>Agree we have the right to remove any and/or all of your content and terminate your account with or without prior notice.</li>
            </ul>
            </li>
            <li>
            <strong>Your Account/Profile.</strong> To access certain parts of the Website, we may require you to create an account and/or profile. All such information provided by you shall be accurate and you agree to keep said information up to date. Anyone whose privilege to use the Website was previously terminated by us may not register for another profile or account, nor use another’s profile or account to use the Website or create an account on your behalf. Sharing your User ID is prohibited, and therefore we assume that access to the Website through your User ID is, in fact, you. You are solely responsible for any and all access to the Website by persons using your User ID. If you believe your User ID is being used without authorization, notify us immediately by one of the methods listed in the Contact section of these Terms of Use.
            </li>
            <li>
            <strong>Prohibited Conduct.</strong> By using the Website, you agree not to:
            <ul>
                <li>Conduct or promote illegal activities;</li>
                <li>Attempt to reverse engineer or otherwise attempt to derive the source code of the software (including tools, methods, processes, and infrastructure);</li>
                <li>Engage in the automated use of the system, such as the use of data mining robots, or any manual process to harvest information from the Website;</li>
                <li>Attempt to gain access to secured portions of the Website;</li>
                <li>Hack into the accounts/profiles of other Users;</li>
                <li>Infringe upon the copyright of us or third parties whose content is hosted on the Website;</li>
                <li>Harass other Users of the Website;</li>
                <li>Violate the rights of other Users of the Website;</li>
                <li>Post materials that we deem to be inappropriate or offensive;</li>
                <li>Sell or otherwise transfer your account/profile without our express permission;</li>
                <li>Use the Website to generate unsolicited email advertisements or spam;</li>
                <li>Use our services as part of any effort to compete with us;</li>
                <li>Interfere in any way with the proper functioning of the Website;</li>
            </ul>
            </li>
            <li>
            <strong>Links to Other Sites.</strong> We may have links to third-party websites that are not owned, controlled or operated by us. We do not assume any responsibility for the content, privacy policies, or practices of any other websites or their owners. We are not endorsing or recommending any other websites, products or services by the simple inclusion of a link to another website. If you choose to access a third-party link it is at your own risk.
            </li>
            <li>
            <strong>Promotions, Sweepstakes and other Contests.</strong> We reserve the right to, on occasion, offer special promotions, contests and/or sweepstakes intended to provide you an incentive to use or promote the Website or engage with advertisers or others associated with the Website. The applicable rules will be posted on the Website in or near the description of each such promotion. We reserve the right to interpret the rules related to any such promotion, contest or sweepstakes in our sole discretion, and you hereby agree to our interpretation.
            </li>
            <li>
            <strong>Products or Services.</strong> The following terms in these Terms of Use are applicable to those who make a purchase of services through the Website.
            <ul>
                <li>
                <strong>Product Descriptions.</strong> We make great effort to put accurate product information, descriptions and images on the Website, but will not be held responsible for any mistakes or omissions to any information made available. The description of products are subject to change at any time without notice, at our sole discretion.
                </li>
                <li>
                <strong>Modifications.</strong> Prices for our services are subject to change without notice. We reserve the right at any time to modify or discontinue any services (or any part or content thereof) without notice at any time. We shall not be liable to you or any third party for any modification, suspension or discontinuance of any of our services.
                </li>
                <li>
                <strong>Tax.</strong> If required by law, tax will be collected from a user at the time of purchase.
                </li>
                <li>
                <strong>Payment for Services.</strong> Full payment for services are made upon placing an order.
                </li>
                <li>
                <strong>Method of Payment.</strong> We accept the following methods of payment: The accepted payment methods are based on those accepted by Stripe, which is our provider.
                </li>
                <li>
                <strong>Discretion.</strong> We reserve the right but are not obligated, to limit the sales of our services to any person, geographic region or jurisdiction. We may exercise this right on a case-by-case basis. We reserve the right to discontinue any product at any time. Any offer for any services made on this site is void where prohibited.
                </li>
            </ul>
            </li>
            <li>
            <strong>Delivery.</strong> Delivery dates are estimates, and time is not of the essence for the delivery dates. All shipments will be made Ex Works from our shipping location unless otherwise specified. Delivery to a carrier constitutes delivery to you. A product purchased under these Terms of Use shall be deemed accepted by you and title will transfer to you upon shipment. It is your responsibility to seek compensation from the carrier for damaged or missing products. We are not responsible for any claims or damages resulting from a delay in delivery or failure to perform which results from force majeure.
            </li>
            <li>
            <strong>Subscription.</strong> If you signed up for one or more subscription services through the Website, these additional terms apply.
            <ul>
                <li>
                <strong>Term.</strong> Your license to the services is valid during the period your subscription remains in good standing and there are no outstanding subscription payments (hereinafter “Subscription Term”). To keep your Subscription Term valid and in force, you must pay all charges to your account relating to your subscriptions, including applicable taxes and fees. Unless otherwise provided, your Subscription Term is on an automatic renewal and you will be required to pay, through the payment method provided by you, for the next applicable Subscription Term at the beginning of the Subscription Term period. These charges are applied regardless of whether you continue to use the Services during the Subscription Term. <strong>YOU ARE RESPONSIBLE FOR SUBSCRIPTION FEES UNTIL YOU CANCEL YOUR SUBSCRIPTION OR IT IS OTHERWISE TERMINATED.</strong>
                </li>
                <li>
                <strong>Discontinuance.</strong> We may discontinue offering subscriptions and will cease charging you accordingly. We may also terminate your Subscription in our sole discretion subject to your right to a pro-rated refund of fees.
                </li>
                <li>
                <strong>Increase of Prices.</strong> We may increase subscription fees and/or terms with 10 days’ notice to you. You may terminate your services before the increase in fees or renewal terms is put into effect. If you do not cancel, you will be charged the new rate at the time of the renewal of your Subscription Term the increased amount and/or your renewed Subscription Term will be the new applicable length.
                </li>
                <li>
                <strong>Failed Payment for Renewal.</strong> If your payment on file is not approved on the date of the renewal of the Subscription Term, you will be allowed a period of at least three business days to provide updated payment information. If no updated information is provided after the three business-day period, we may suspend your service and terminate the Subscription. If we make a charge to your credit card and it is declined, we may, but are not obligated to make up to five more attempts to bill the card over a thirty-day period. We also reserve the right to charge your card smaller amounts in more than one transaction not to exceed the amount of the due Subscription Term payment. If you or we (through our payment service providers) update your payment method to remedy a change in validity or expiration date, we will automatically resume billing you for your subscription to the Services. We reserve the right not to reactive an account or subscription until all past due amounts are paid.
                </li>
                <li>
                <strong>Cancellation.</strong> Cancellation of your free subscription is to be done according to the following: You may cancel your subscription at any time. After cancellation, the service will remain active until the remaining subscription period ends. After termination, you may not have access to your account or the services related to your subscription.
                </li>
            </ul>
            </li>
            <li>
            <strong>Free Trial.</strong> We may offer free trial memberships. Free trials require the use of a credit/debit card. Free trials last a period of 30 days. Although the free trial membership may be advertised as free, you authorize us to charge your credit card $1 to confirm the source of the payment. At the end of a trial or promotional membership you will lose access to the services offered through the trial or promotional membership. You are only eligible for one trial or promotional membership. Attempting to sign up for an additional trial or promotional membership will result in you agreeing to sign up for the subscription. To cancel your free trial, contact us through one of the methods listed in the Contact section of these Terms of Use. After you have cancelled, your free trial will remain active through the end of the then-current Subscription Term.
            </li>
            <li>
            <strong>General Maintenance.</strong> We may make the website unavailable for periods of time for general maintenance. The length of such unavailability will be kept to a minimum, but we offer no guaranty as to a length of time the website may be unavailable. We are not liable for any issue that may arise from such unavailability.
            </li>
            <li>
            <strong>Termination.</strong> We reserve the right to alter or discontinue the Website or any of the services provided herein at any time without prior notice. We also reserve the right to terminate these Terms of Use at our election and for any reason, without prior notice. The agreement between us and you will automatically terminate if, in our sole discretion, you violate any of These Terms of Use set forth below. A termination will result in the immediate cessation of access to the Website. The Disclaimers of Warranty and Limitation of Liability, and Indemnity sections shall survive the termination of the agreement between you and us.
            </li>
            <li>
            <strong>Disclaimers of Warranty.</strong> We provide the Website and the related goods and services "as is", “where is”, and "as available." We make no express and/or implied warranties or guarantees about the Website, the goods and services described thereon or the Advertisers. <strong>TO THE MAXIMUM EXTENT PERMITTED BY LAW, WE AND OUR OFFICERS, DIRECTORS, AGENTS, VENDORS, AND THE MERCHANTS WHO ADVERTISE WITH US DISCLAIM ANY AND ALL EXPRESS OR IMPLIED WARRANTIES THAT THE WEBSITE AND SERVICES ARE MERCHANTABLE, OF SATISFACTORY QUALITY, ACCURATE, TIMELY OR FIT FOR A PARTICULAR PURPOSE OR NEED.</strong> WE IN NO WAY GUARANTEE THAT WE WILL MEET YOUR REQUIREMENTS, IS ERROR-FREE, ACCURATE, RELIABLE, WITHOUT INTERRUPTION OR AVAILABLE AT ALL TIMES. WE MAKE NO GUARANTEE THAT YOU WILL BE ABLE TO ACCESS OR USE THE WEBSITE AT TIMES OR LOCATIONS OF YOUR CHOOSING. WE DISCLAIM, TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, (A) ANY ENDORSEMENT OF OR LIABILITY FOR CONTENT AND HYPERLINKS; (B) INACCURACY, INCOMPLETENESS OR TIMELINESS OF THE SERVICES; (C) THE TRANSMISSION OF VIRUSES OR THE OCCURRENCE OF DATA CORRUPTION; AND (D) DAMAGES AS A RESULT OF THE TRANSMISSION, USE OR INABILITY TO USE THE SERVICES, INCLUDING THE UNAVAILABILITY OF THE SERVICES, OR CIRCUMSTANCES OVER WHICH THE ORGANIZATION HAS NO CONTROL.
            </li>
            <li>
            <strong>Limitations of Liability.</strong> WE, OUR AFFILIATES, OFFICERS, DIRECTORS, AGENTS, VENDORS, AND MERCHANTS WHO ADVERTISE HERE, SHALL NOT BE LIABLE FOR ANY INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES ARISING FROM YOUR USE OF, INABILITY TO USE, OR RELIANCE UPON THE WEBSITE IN ANY WAY. THESE EXCLUSIONS APPLY TO ANY CLAIMS FOR LOST PROFITS, LOST DATA, LOSS OF GOODWILL, WORK STOPPAGE, COMPUTER FAILURE OR MALFUNCTION, DAMAGE TO ANY OTHER EQUIPMENT, OR ANY OTHER COMMERCIAL DAMAGES OR LOSSES, EVEN IF WE KNEW OR SHOULD HAVE KNOWN OF THE POSSIBILITY OF SUCH DAMAGES. IF ANY JURISDICTION DOES NOT ALLOW THE EXCLUSION OR THE LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, IN SUCH JURISDICTION, OUR LIABILITY, AND THE LIABILITY OF OUR AFFILIATES, OFFICERS, DIRECTORS, AGENTS, VENDORS, AND MERCHANTS WHO ADVERTISE HERE, SHALL BE LIMITED TO THE EXTENT PERMITTED BY LAW. YOUR SOLE AND EXCLUSIVE REMEDY FOR ANY OF THE ABOVE DISPUTES OR CLAIMS WITH US IS TO CEASE USE OF THE WEBSITE. NOTHING IN THESE TERMS OF USE SHALL BE DEEMED TO EXCLUDE OR LIMIT YOUR LIABILITY IN RESPECT OF ANY INDEMNITY GIVEN BY YOU UNDER THESE TERMS OF USE. THE FOREGOING DOES NOT AFFECT ANY LIABILITY WHICH CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
            <p>
                <strong>
                In connection with the foregoing release, you hereby waive California Civil Code Section 1542 (and any similar provision in any other jurisdiction) which states: “A general release does not extend to claims which the creditor does not know or suspect to exist in his favor at the time of executing the release, which, if known by him must have materially affected his settlement with the debtor.”
                </strong>
            </p>
            </li>
            <li>
            <strong>Indemnity.</strong> You agree to defend, indemnify and hold harmless us, our officers, directors, representatives, employees and agents and all of our parent companies, affiliates, related companies and their officers, directors, representatives, employees and agents from and against any and all claims, damages, obligations, losses, liabilities, costs or debt, and expenses (including but not limited to reasonable attorney's fees) arising from: (i) your violation of any term of these Terms of Use; or (ii) your violation of any third party right, including without limitation any copyright, property, privacy right, or any and all intangible or intellectual property rights;
            </li>
            <li>
            <strong>Intellectual Property.</strong>
            <ul>
                <li>
                We, or people from whom we obtained a license, retain ownership of all intellectual property rights of any kind associated with the Website, including all applicable trademarks, copyrights and other proprietary rights such as trade secrets. Through the use of this Website pursuant to these Terms of Use, you have a limited right to use the Website, but in no way are we granting any license to you under any of those intellectual property rights. We reserve all rights that are not expressly granted to you in these Terms of Use. You may print limited numbers of one or more pages from the Website for your personal use.
                </li>
                <li>
                We retain, to the maximum extent possible, all ownership, without limitation, of all the text, software, scripts, graphics, photos, sounds, interactive features and the trademarks, service marks and logos contained therein ("Marks") unless they are marks used by Merchants who have provided them to us for use on this Website. The Marks are owned or licensed to us, subject to copyright and other intellectual property rights under the law of the United States of America, the law of the jurisdiction where you reside, and international conventions. All content on the Website provided by us is provided to you “AS IS” for your information and personal use only and may not be used, copied, reproduced, modified, distributed, transmitted, broadcast, displayed, sold, licensed, or otherwise exploited for any other purposes whatsoever without the prior written consent of the respective owners. We reserve all rights not expressly granted in and to the Website.
                </li>
            </ul>
            </li>
            <li>
            <strong>Entire Agreement.</strong> These Terms of Use, including the Privacy Policy, constitutes the entire agreement of the parties with respect to the subject matter hereof. No waiver by either party of any breach or default hereunder is a waiver of any preceding or subsequent breach or default.
            </li>
            <li>
            <strong>Choice of Law.</strong> To the maximum extent authorized by law, the laws of the State of Ohio govern the interpretation of these Terms of Use and any disputes arising in connection with it, regardless of conflict of laws principles.
            </li>
            <li>
            <strong>Dispute Resolution.</strong> Any claim or dispute between you and us, our officers, directors, agents, parent companies and vendors that arises out of or relates to this Agreement or use of the Website shall be decided exclusively by binding arbitration. Arbitration shall be initiated in Amelia, Ohio or shall be done entirely remote. Arbitration shall be subject to the Federal Arbitration Act and not any state arbitration law. Unless otherwise agreed upon by the parties in writing, the arbitration will be conducted before one arbitrator and will be governed by the American Arbitration Association’s (“AAA”) Commercial Arbitration Rules and, if the arbitrator deems them applicable, the Supplementary Procedures for Consumer Related Disputes (collectively, the “Rules and Procedures”). To the fullest extent permitted by applicable law, you and we must abide by the following rules: (1) <strong>ANY CLAIMS BROUGHT BY YOU OR US MUST BE BROUGHT IN THE PARTY’S INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING;</strong> (2) <strong>THE ARBITRATOR MAY NOT CONSOLIDATE MORE THAN ONE PERSON’S CLAIMS, MAY NOT OTHERWISE PRESIDE OVER ANY FORM OF A REPRESENTATIVE OR CLASS PROCEEDING, AND MAY NOT AWARD CLASS-WIDE RELIEF;</strong> (3) the arbitration shall be confidential, and neither you nor we may disclose the existence, content or results of any arbitration, except as may be required by law or for purposes of enforcement of the arbitration award; and (4) the arbitrator may award any individual relief or individual remedies that are permitted by applicable law.
            </li>
            <li>
            <strong>Headings.</strong> The section headings used herein are for convenience only and shall be of no legal force or effect.
            </li>
            <li>
            <strong>Severability.</strong> If any provision of these Terms of Use is held invalid by a court of competent jurisdiction, such invalidity shall not affect the enforceability of any other provisions contained in these Terms of Use, and the remaining portions of these Terms of Use shall continue in full force and effect.
            </li>
            <li>
            <strong>Third Party Beneficiaries.</strong> There are no third-party beneficiaries of these Terms of Use. You may not assign these Terms of Use or your rights and obligations hereunder, in whole or in part, to any third party without our prior written consent, and any attempt by you to do so will be invalid. No one shall be deemed a third-party beneficiary to these Terms of Use.
            </li>
            <li>
            <strong>Contact.</strong> DeepLayered is located at 51 Falcon Circle, Amelia, Ohio 45102. If you have questions about these Terms of Use, you may contact DeepLayered by:
            <ul>
                <li>
                Email at <a href="mailto:contact@deeplayered.ai">contact@deeplayered.ai</a>;
                </li>
            </ul>
            </li>
        </ol>
        </div>
        <HomepageFooter />

    </div>
  );
}

export default TermsOfUse;
