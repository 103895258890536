import React, { useState, useEffect, useCallback, useRef } from 'react';
import './GroupFocus.css';
import { FaTimes } from 'react-icons/fa';
import { v4 as uuidv4 } from 'uuid';
import { useMyContext } from '../../context/ProjectProvider';
//import { predefinedNodes } from './nodes';
import { MathJaxContext, MathJax } from 'better-react-mathjax';
//import ReactMarkdown from 'react-markdown';
//import remarkMath from 'remark-math';
//import rehypeKatex from 'rehype-katex';
import 'katex/dist/katex.min.css';

const GroupFocus = ({ onClose, mode, element }) => {
  const { currentProject, addOrModifyElementinProjectAndSave, predefinedNodes } = useMyContext();

  const [name, setName] = useState(element?.name || '');
  const [initialName, setInitialName] = useState(element?.name || '');
  const [initialText, setInitialText] = useState(element?.text || '');
  const [text, setText] = useState(element?.text || '');
  const [isOpen, setIsOpen] = useState(false);
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [nameExists, setNameExists] = useState(false);
  const [activeButton, setActiveButton] = useState('square');
  const [openFolders, setOpenFolders] = useState({
    independent: true,
    destilators: true,
    streams: true,
    streams2: true,
  });

  useEffect(() => {
    setIsOpen(true);
  }, []);

  const checkNameExists = useCallback(
    (name) => {
      const exists = currentProject.elements.some(
        (el) => el.name.toLowerCase() === name.toLowerCase()
      );
      setNameExists(exists);
    },
    [currentProject.elements]
  );
  const [textColor] = useState('#b0b0b0'); // Color de texto predeterminado (negro)
  const charLimit = 2770;

  const handleTextChange = (e) => {
    if (e.target.value.length <= charLimit) {
      setText(e.target.value);
      e.target.style.height = 'auto'; // Restablece la altura
      e.target.style.height = `${e.target.scrollHeight}px`; // Ajusta la altura al contenido
    }
  };
  useEffect(() => {
    if (mode === 'modify' && element && element.icon) {
      setActiveButton(element.icon);
    }
  }, [mode, element]);
  

  const handleCreateOrModifyElementSideBar = () => {
    checkNameExists(name); // Verifica si el nombre ya existe
    if (nameExists && mode === 'create') return;

    const newElement = {
        id: element ? element.id : uuidv4(),
        icon: activeButton,
        name,
        text,
        instances: element ? element.instances : [],
    };
    addOrModifyElementinProjectAndSave(newElement);
    setInitialName(name);
    setInitialText(text);
    handleClose(false);
};
useEffect(() => {
  checkNameExists(name);
}, [name, checkNameExists]);


  const handleButtonClick = (nodeKey) => {
    setActiveButton(nodeKey);
  };

  const handleClose = (checkChanges = true) => {
    if (
      checkChanges &&
      (name !== initialName || text !== initialText) &&
      (mode === 'create' || mode === 'modify')
    ) {
      setShowConfirmationPopup(true);
    } else {
      setIsOpen(false);
      setTimeout(onClose, 300);
    }
  };

  const handleConfirmClose = () => {
    setIsOpen(false);
    setTimeout(onClose, 300);
  };

  const isSaveDisabled = name === initialName && text === initialText;
  const isCreateDisabled = name.trim() === '' || text.trim() === '' || nameExists;

  const PortLabel = ({ portName, portNode }) => {
    const textRef = useRef(null);
    const [textDimensions, setTextDimensions] = useState({ width: 0, height: 0 });

    useEffect(() => {
      if (textRef.current) {
        const { width, height } = textRef.current.getBBox();
        setTextDimensions({ width, height });
      }
    }, [portName]);

    const rectPadding = 4;
    const rectWidth = textDimensions.width + rectPadding * 2;
    const rectHeight = textDimensions.height + rectPadding * 2;

    return (
      <g transform={`translate(${portNode.x}, ${portNode.y})`}>
        <line
          x1="0"
          y1="0"
          x2="-5"
          y2="-6"
          stroke="#333"
          strokeWidth="1"
          className="port-label-arrow"
        />
        <rect
          x={-rectWidth / 2}
          y={-17}
          width={rectWidth - 5}
          height={rectHeight - 7}
          rx="2"
          className="port-label-background"
        />
        <text
          ref={textRef}
          x="-3"
          y={-20 + rectHeight / 2}
          className="port-label"
          textAnchor="middle"
          alignmentBaseline="middle"
        >
          {portName}
        </text>
      </g>
    );
  };

  const ElementPreview = ({ node }) => {
    if (!node || !node.nodes) {
      return null;
    }

    const shape = node;

    return (
      <svg className="svg-instance" preserveAspectRatio="xMidYMid meet" viewBox="0 -12 70 100">
        {shape.edges &&
          shape.edges.map((edge, index) => {
            const startNode = shape.nodes.find((node) => node.id === edge.beginning);
            const endNode = shape.nodes.find((node) => node.id === edge.end);
            if (!startNode || !endNode) return null;
            return (
              <line
                key={`edge-${index}`}
                x1={startNode.x}
                y1={startNode.y}
                x2={endNode.x}
                y2={endNode.y}
                stroke="black"
                strokeWidth="1"
              />
            );
          })}
        {shape.nodes &&
          shape.nodes.map((node) => (
            <circle key={`node-${node.id}`} cx={node.x} cy={node.y} r={2} fill="blue" />
          ))}
        {shape.ports &&
          Object.entries(shape.ports).map(([portName, nodeId], index) => {
            const portNode = shape.nodes.find((node) => `node-${node.id}` === nodeId);
            if (!portNode) return null;

            return (
              <PortLabel key={`label-${index}`} portName={portName} portNode={portNode} />
            );
          })}
      </svg>
    );
  };

  const ElementThumbnail = ({ node, lineWidth = 1, nodeSize = 2 }) => {
    if (!node || !node.nodes) {
      return null;
    }

    const shape = node;

    return (
      <svg
        className="thumbnail-svg"
        preserveAspectRatio="xMidYMid meet"
        viewBox="-5 -5 80 110" // Ajuste del viewBox para incluir márgenes
      >
        {shape.edges &&
          shape.edges.map((edge, index) => {
            const startNode = shape.nodes.find((n) => n.id === edge.beginning);
            const endNode = shape.nodes.find((n) => n.id === edge.end);
            if (!startNode || !endNode) return null;
            return (
              <line
                key={`edge-${index}`}
                x1={startNode.x}
                y1={startNode.y}
                x2={endNode.x}
                y2={endNode.y}
                className="thumbnail-edge"
                strokeWidth={lineWidth}
              />
            );
          })}
        {shape.nodes &&
          shape.nodes.map((node) => (
            <circle
              key={`node-${node.id}`}
              cx={node.x}
              cy={node.y}
              r={nodeSize}
              className="thumbnail-node"
            />
          ))}
      </svg>
    );
  };
  const getActiveNode = () => {
    let activeNode;
    let found = false;

    if (predefinedNodes.independent?.nodes[activeButton]) {
      activeNode = predefinedNodes.independent.nodes[activeButton];
      found = true;
    } else {
      for (const folderKey in predefinedNodes) {
        if (folderKey !== 'independent' && predefinedNodes[folderKey].nodes[activeButton]) {
          activeNode = predefinedNodes[folderKey].nodes[activeButton];
          found = true;
          break;
        }
      }
    }

    if (found) {
      // Asegurar que activeNode.formulas exista
      activeNode.formulas = activeNode.formulas || [];
    }

    return activeNode;
  };

  return (
    <div className={`group-focus-overlay ${isOpen ? 'open' : ''}`} onClick={handleClose}>
      <div
        className={`group-focus ${isOpen ? 'open' : ''} ${!isOpen ? 'closing' : ''}`}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="group-focus-header">
          <div className="group-focus-header-left">
          <button onClick={() => handleClose(true)} className="close-button">
  <i className="bi bi-x-lg close-icon"></i>
</button>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Node name"
              maxLength="50"
              style={{ color: '#d1d1d1' }}
            />
          </div>
          <button
            onClick={handleCreateOrModifyElementSideBar}
            className="save-button"
            disabled={mode === 'create' ? isCreateDisabled : isSaveDisabled}
            data-tooltip={
              nameExists
                ? 'Name of existing element'
                : mode === 'create'
                ? 'Please enter a name and characteristics'
                : 'No changes were made'
            }
          >
            {mode === 'create' ? <i className="bi bi-save"></i> : <i className="bi bi-floppy2"></i>}
          </button>
        </div>
        <div className="main-layout">
        <div className="predefined-buttons">
          <h4>{mode === 'modify' ? 'Node type' : 'Nodes tree'}</h4>
          {mode === 'modify' ? (
            // En modo 'modify', muestra solo el botón activo
            <button
              key={activeButton}
              className="predefined-button active"
              onClick={() => handleButtonClick(activeButton)}
            >
              <div className="button-content">
                <div className="button-thumbnail">
                  <ElementThumbnail
                    node={getActiveNode()}
                    lineWidth={1.5}
                    nodeSize={3}
                  />
                </div>
                <span>{getActiveNode()?.displayName || activeButton}</span>
              </div>
            </button>
          ) : (
            // En modo 'create', muestra todos los botones
            <>
              {predefinedNodes.independent &&
                Object.keys(predefinedNodes.independent.nodes).map((nodeKey) => (
                  <button
                    key={nodeKey}
                    className={`predefined-button ${activeButton === nodeKey ? 'active' : ''}`}
                    onClick={() => handleButtonClick(nodeKey)}
                  >
                    <div className="button-content">
                      <div className="button-thumbnail">
                        <ElementThumbnail
                          node={predefinedNodes.independent.nodes[nodeKey]}
                          lineWidth={1.5}
                          nodeSize={3}
                        />
                      </div>
                      <span>{predefinedNodes.independent.nodes[nodeKey].displayName || nodeKey}</span>
                    </div>
                  </button>
                ))}
              {Object.keys(predefinedNodes)
                .filter((folderKey) => folderKey !== 'independent')
                .map((folderKey) => {
                  const folder = predefinedNodes[folderKey];
                  return (
                    <div key={folderKey} className="folder">
                      <div
                        className="folder-title"
                        onClick={() =>
                          setOpenFolders((prev) => ({
                            ...prev,
                            [folderKey]: !prev[folderKey],
                          }))
                        }
                      >
                        {openFolders[folderKey] ? (
                          <i className="bi bi-caret-down-fill"></i>
                        ) : (
                          <i className="bi bi-caret-right-fill"></i>
                        )}
                        {folder.displayName}
                      </div>
                      {openFolders[folderKey] &&
                        Object.keys(folder.nodes).map((nodeKey) => (
                          <button
                            key={nodeKey}
                            className={`predefined-button ${activeButton === nodeKey ? 'active' : ''}`}
                            onClick={() => handleButtonClick(nodeKey)}
                          >
                            <div className="button-content">
                              <div className="button-thumbnail">
                                <ElementThumbnail
                                  node={folder.nodes[nodeKey]}
                                  lineWidth={3.5} // Ajusta el grosor de las líneas aquí
                                  nodeSize={2} // Ajusta el tamaño de los nodos aquí
                                />
                              </div>
                              <span>{folder.nodes[nodeKey].displayName || nodeKey.replace(/_/g, ' ')}</span>
                            </div>
                          </button>
                        ))}
                    </div>
                  );
                })}
            </>
          )}
        </div>
          <div className="contenedor-nodes">
            <div className="contenedor-top">
              <div className="contenedor-a">
                <h4>Item preview</h4>
                <div className="contenedor-a-img">
                  {activeButton && <ElementPreview node={getActiveNode()} />}
                </div>
              </div>
              <div className="contenedor-b">
                <h4 className="contenedorB-titulo">Node equations</h4>
                <div className="contenedorB-contenido">
                  <MathJaxContext>
                    <div>
                      {getActiveNode()?.formulas && getActiveNode().formulas.length > 0 ? (
                        getActiveNode().formulas.map((formulaObj, index) => (
                          <div key={index} style={{ marginBottom: '1em' }}>
                            <p>
                              <strong>Description:</strong> {formulaObj.description}
                            </p>
                            <MathJax>
                              <p>{`\\(${formulaObj.formula}\\)`}</p>
                            </MathJax>
                          </div>
                        ))
                      ) : (
                        <p>No formulas available for this element.</p>
                      )}
                    </div>
                  </MathJaxContext>
                </div>
              </div>
            </div>
            <div className="contenedor-c">
              <h3 className="group-focus-title">Node properties and details</h3>
              <textarea
                value={text}
                onChange={handleTextChange}
                style={{ height: '300px', width: '100%', color: textColor }}
                placeholder="Type here..."
              />
              <span className="char-count">{text.length} / {charLimit}</span>
              {/* Opcional: control de color */}
            </div>
          </div>
        </div>

        {showConfirmationPopup && (
          <div className="popup" onClick={() => setShowConfirmationPopup(false)}>
            <div className="popup-content" onClick={(e) => e.stopPropagation()}>
              <div className="popup-line">
                {mode === 'create'
                  ? "Are you sure you don't want to create the item?"
                  : "Are you sure you don't want to save the item?"}
                <button className="close-button" onClick={() => setShowConfirmationPopup(false)}>
                  <FaTimes />
                </button>
              </div>
              <div className="popup-buttons">
                <button
                  className="cancel-button"
                  onClick={() => setShowConfirmationPopup(false)}
                >
                  Cancelar
                </button>
                <button className="delete-button" onClick={handleConfirmClose}>
                  {mode === 'create' ? 'Close without creating' : 'Close without saving'}
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default GroupFocus;
