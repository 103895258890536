import React, { useState, useEffect, useRef, useCallback } from 'react';
import ElementItem from '../../ElementItem/ElementItem';
import './Elements.css';
import { FaTimes } from 'react-icons/fa';
import GroupFocus from '../../GroupFocus/GroupFocus';
import { useMyContext } from '../../../context/ProjectProvider';

const Elements = ({ elementsVisible, onSelectElementForDiagram, selectedInstances }) => {
    const { resetSelections } = useMyContext();
    const [showPopup, setShowPopup] = useState(false);
    const [elementToDelete, setElementToDelete] = useState(null);
    const [elementToModify, setElementToModify] = useState(null);
    const [showGroupFocus, setShowGroupFocus] = useState(false);
    const [mode, setMode] = useState('create');

    const { currentProject, deleteElementInProjectAndSave, setCurrentProject } = useMyContext(); 

    const containerRef = useRef(null);

    const handleClickOutside = useCallback((event) => {
        if (containerRef.current && !containerRef.current.contains(event.target)) {
            onSelectElementForDiagram(null);
        }
    }, [onSelectElementForDiagram]);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [handleClickOutside]);

    const handleModify = (element) => {
        resetSelections();
        setElementToModify(element);
        setShowGroupFocus(true);
        setMode('modify');
    };

    const handleShowPopup = (element) => {
        setElementToDelete(element);
        setShowPopup(true);
    };

    const handleCancelClick = () => {
        setShowPopup(false);
        setElementToDelete(null);
    };

    const handleDeleteClick = () => {
        if (elementToDelete) {
            deleteElementInProjectAndSave(elementToDelete.id);
        }
        setShowPopup(false);
        setElementToDelete(null);
    };

    // Function to mark all instances of a given element as seleccionado: true
    // and deselect all instances of other elements
    const markInstancesAsSeleccionado = (elementId) => {
        setCurrentProject(prevProject => {
            // Create a deep copy of the previous project state
            const updatedProject = JSON.parse(JSON.stringify(prevProject));

            // Deselect all instances first
            updatedProject.elements.forEach(el => {
                el.instances.forEach(instance => {
                    instance.seleccionado = false;
                });
            });

            // Find the element with the given ID and select its instances
            const element = updatedProject.elements.find(el => el.id === elementId);
            if (element && element.instances) {
                element.instances.forEach(instance => {
                    instance.seleccionado = true;
                });
            }

            return updatedProject;
        });
    };

    const handleElementClick = (element) => {

        markInstancesAsSeleccionado(element.id);

        onSelectElementForDiagram(element);
    };

    const sortedElements = currentProject.elements ? [...currentProject.elements].sort((a, b) => a.name.localeCompare(b.name)) : [];

    return (
        <div ref={containerRef} className={`elements ${elementsVisible ? '' : 'collapsed'}`}>
            <h3 className="item-list-title">Nodes list</h3>
            {sortedElements.map((element) => (
                <ElementItem
                    key={element.id}
                    element={element}
                    onDelete={handleShowPopup}
                    onModify={handleModify}
                    onClick={() => handleElementClick(element)}
                 />
            ))}
            {showPopup && (
                <div className="popup">
                    <div className="popup-content">
                        <div className="popup-line">
                            Confirm delete?
                            <button className="close-button" onClick={handleCancelClick}>
                                <FaTimes />
                            </button>
                        </div>
                        <div className="divider"></div>
                        <div className="popup-message">
                            Do you want to delete {elementToDelete ? elementToDelete.name : ''}?
                        </div>
                        <div className="popup-buttons">
                            <button className="cancel-button" onClick={handleCancelClick}>Cancel</button>
                            <button className="delete-button" onClick={handleDeleteClick}>Delete</button>
                        </div>
                    </div>
                </div>
            )}
            {showGroupFocus && (
                <GroupFocus
                    onClose={() => setShowGroupFocus(false)}
                    element={elementToModify}
                    mode={mode}
                />
            )}
        </div>
    );
};

export default Elements;  
