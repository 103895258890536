import React from 'react';
import './HomepageHeader.css';
import { ReactComponent as Logo } from '../../Assets/logo.svg';
import { useNavigate, useLocation } from 'react-router-dom';
import { scroller } from 'react-scroll';

function HomePageHeader() {
  const navigate = useNavigate();
  const location = useLocation();

  // Función para manejar el clic en 'header-left'
  const handleHeaderLeftClick = () => {
    if (location.pathname !== '/') {
      navigate('/');
    } else {
      // Si ya estamos en '/', desplazamos al inicio de la página
      window.scrollTo(0, 0);
    }
  };

  // Función para enviar eventos a Google Tag Manager
  const handleLinkClick = (sectionId) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'header_link_click',
      link_label: sectionId === 'productsSection' ? 'Products' : 'Contact',
    });

    if (location.pathname !== '/') {
      // Navegar a la ruta "/" y pasar el estado con la sección a la que se debe hacer scroll
      navigate('/', { state: { scrollTo: sectionId } });
    } else {
      // Si ya estamos en "/", hacer scroll directamente
      scroller.scrollTo(sectionId, {
        smooth: true,
        duration: 500,
        offset: -50,
      });
    }
  };

  return (
    <div className="header-container">
      <header className="homepage-header">
        <div
          className="header-left"
          style={{ cursor: 'pointer' }}
          onClick={handleHeaderLeftClick}
        >
          <Logo className="header-logo" />
          <div className="header-title" translate="no">Deeplayered</div>
        </div>
        <div className="header-links">
          <span
            className="header-link"
            style={{ cursor: 'pointer' }}
            onClick={() => handleLinkClick('productsSection')}
          >
            Products
          </span>
          <span
            className="header-link"
            style={{ cursor: 'pointer' }}
            onClick={() => handleLinkClick('contactForm')}
          >
            Contact
          </span>
        </div>
      </header>
    </div>
  );
}

export default HomePageHeader;
