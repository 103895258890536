import React, { useState, useEffect } from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './MiniHeader.css';
import { FaTimes } from 'react-icons/fa';
import { useMyContext } from '../../../context/ProjectProvider';

const MiniHeader = ({ onCreateElement, onToggleElements, elementsVisible, onToggleGrid }) => {
    const { resetSelections } = useMyContext();
    const [showPopup, setShowPopup] = useState(false);
    const [gridButtonActive, setGridButtonActive] = useState(false);

    const {
        currentProject,
        setCurrentProject,
        saveProject,
        selectedCount,
        deleteElementInstanceAndAttachedFlowLinesInProject,
        deleteFlowlineInstanceInProject,
        id
    } = useMyContext();

    const handleTrashClick = () => {
        setShowPopup(true);
    };

    const handleCancelClick = () => {
        setShowPopup(false);
    };

    const handleDeleteClick = () => {
        const selectedInstances = currentProject.elements
            .flatMap(element => 
                element.instances
                    .filter(instance => instance.seleccionado)
                    .map(instance => ({
                        instanceId: instance.instance_id,
                        parentElementId: element.id
                    }))
            );
    
        const selectedFlowlines = currentProject.flowLines
            .filter(flowline => flowline.seleccionado)
            .map(flowline => flowline.id);
    
        selectedInstances.forEach(({ instanceId, parentElementId }) => {
            deleteElementInstanceAndAttachedFlowLinesInProject(instanceId, parentElementId);
        });
    
        selectedFlowlines.forEach(flowlineId => {
            deleteFlowlineInstanceInProject(flowlineId);
        });
    
        setShowPopup(false);
    };

    const toggleLock = () => {
        setCurrentProject(prevProject => ({
            ...prevProject,
            canModifyDiagram: !prevProject.canModifyDiagram
        }));
    };

    const toggleGridButton = () => {
        const newState = !gridButtonActive;
        setGridButtonActive(newState);
        resetSelections();  // Usar resetSelections pasado como prop
        onToggleGrid(newState);
    };

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.keyCode === 46 || event.keyCode === 8) {  // 46 es "Delete" y 8 es "Backspace"
                if (selectedCount >= 1) {
                    setShowPopup(true);  // Muestra el popup de confirmación si hay elementos seleccionados
                }
            }
        };
    
        window.addEventListener('keydown', handleKeyDown);
        
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [selectedCount]);    

    return (
        <div className="mini-header">
            <div className='left-buttons'>
            <button
                className={`create-element-button ${elementsVisible ? '' : 'collapsed'}`}
                onClick={() => {
                    resetSelections();  // Usar resetSelections pasado como prop
                    onCreateElement();
                }}
            >
                {elementsVisible ? 'New Node' : <i className="bi bi-node-plus"></i>}
            </button>
                <button 
                    onClick={onToggleElements} 
                    className="btn btn-light toggle-elements-button" 
                    title={elementsVisible ? "Hide" : "Show"}
                    id = "791"
                >
                    {elementsVisible ? <i className="bi bi-arrow-bar-left"></i> : <i className="bi bi-arrow-bar-right"></i>}
                </button>
            </div>
            <div className="right-buttons">
                {selectedCount >= 1 && (
                    <>
                        <button className="trash-button" onClick={handleTrashClick} title="Delete items in diagram">
                            <i className="bi bi-trash"></i>
                        </button>
                        <span className="separator">|</span>
                    </>
                )}
                <button
                    className={`grid-button ${gridButtonActive ? 'active' : ''}`}
                    onClick={toggleGridButton}
                    title="Marquee"
                >
                    <i className="bi bi-grid-3x3-gap"></i>
                </button>
                <button
                    className="lock-button"
                    onClick={toggleLock}
                    title="Prevents LLM from modifying the diagram"
                >
                    {currentProject.canModifyDiagram ? <i className="bi bi-unlock-fill"></i> : <i className="bi bi-lock-fill"></i> }
                </button>
                { id !== "1" && (
                    <>
                    <span className="separator">|</span>
                    <button className="save-button" onClick={() => saveProject(currentProject)} title="Save Diagram">
                        <i className="bi bi-download"></i>
                    </button>
                    </>
                )}
                
            </div>
            {showPopup && (
                <div className="popup">
                    <div className="popup-content">
                        <div className="popup-line">
                            Delete the selected items?
                            <button className="close-button" onClick={handleCancelClick}>
                                <FaTimes />
                            </button>
                        </div>
                        <div className="popup-line">Do you want to delete {selectedCount} {selectedCount === 1 ? 'item' : 'items'}?</div>
                        <div className="popup-buttons">
                            <button className="cancel-button" onClick={handleCancelClick}>Cancel</button>
                            <button className="delete-button" onClick={handleDeleteClick}>Delete</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default MiniHeader;  
