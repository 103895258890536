import React, { useState, useCallback, useRef } from 'react';
import { useDrop } from 'react-dnd';
import PF from 'pathfinding';
import './Diagram.css';
import { useMyContext } from '../../../context/ProjectProvider';

const CELL_SIZE = 5;
const DIAGRAM_WIDTH = 2500;
const DIAGRAM_HEIGHT = 900;
const NODE_RADIUS = 5;
const NUM_CELLS_FIGURE_WIDTH = 15;
const MARGIN = 1; // Margen en celdas (1 celda = 5px)

const Diagram = ({ onInstancesSelected, onFlowlinesSelected, isGridActive, onFlowlineClick }) => {
  const [isDragging, setIsDragging] = useState(false);
  const [hoveredNodePoint, setHoveredNodePoint] = useState(null);
  const [offsets, setOffsets] = useState({});
  const [currentConnectingConnection, setCurrentConnectingConnection] = useState({ start: null, end: null });
  const [selectionRect, setSelectionRect] = useState(null);
  const [isSelecting, setIsSelecting] = useState(false);
  const [selectionStart, setSelectionStart] = useState({ x: 0, y: 0 });
  const [isHoveringFlowlineText, setIsHoveringFlowlineText] = useState(false);
  const scrollAreaRef = useRef(null);

  const {
    currentProject,
    addOrModifyElementInstanceInProject,
    addOrModifyFlowLineInstanceInProject,
    setCurrentProject,
  } = useMyContext();

  // Calcula coordenadas y guarda una nueva instancia
  const [, drop] = useDrop(() => ({
    accept: 'element',
    drop: (item, monitor) => {
      const offset = monitor.getClientOffset();
      const scrollOffset = scrollAreaRef.current.getBoundingClientRect();
      // Asegurar que las coordenadas del ancla sean números enteros
      let x =
        Math.round((offset.x - scrollOffset.left + scrollAreaRef.current.scrollLeft) / CELL_SIZE) *
        CELL_SIZE;
      let y =
        Math.round((offset.y - scrollOffset.top + scrollAreaRef.current.scrollTop) / CELL_SIZE) *
        CELL_SIZE;
      const newElementInstance = {
        instance_id: Date.now(),
        flow_lines: [],
        anchor_coordinates: { x, y },
      };
      addOrModifyElementInstanceInProject(newElementInstance, item.id);
    },
  }));

  // Maneja el evento de mouse down en una instancia
  const handleMouseDown = (e, instance) => {
    e.stopPropagation();
    if (isSelecting || isHoveringFlowlineText) return;

    const scrollAdjustedX = e.clientX + scrollAreaRef.current.scrollLeft;
    const scrollAdjustedY = e.clientY + scrollAreaRef.current.scrollTop;
    setIsDragging(true);

    if (!instance.seleccionado) {
      const updatedElements = currentProject.elements.map((el) => {
        const updatedInstances = el.instances.map((inst) =>
          inst.instance_id === instance.instance_id
            ? { ...inst, seleccionado: true }
            : { ...inst, seleccionado: false }
        );
        return { ...el, instances: updatedInstances };
      });

      const updatedFlowLines = currentProject.flowLines.map((flow) => {
        return { ...flow, seleccionado: false };
      });

      setCurrentProject((prev) => ({
        ...prev,
        elements: updatedElements,
        flowLines: updatedFlowLines,
      }));

      notifySelectedInstances(updatedElements);
    }

    const newOffsets = {};

    currentProject.elements.forEach((el) => {
      el.instances.forEach((inst) => {
        if (inst.seleccionado) {
          newOffsets[inst.instance_id] = {
            x: scrollAdjustedX - inst.anchor_coordinates.x,
            y: scrollAdjustedY - inst.anchor_coordinates.y,
          };
        }
      });
    });

    currentProject.flowLines.forEach((flowline) => {
      if (flowline.seleccionado) {
        newOffsets[flowline.id] = {
          startX: scrollAdjustedX - flowline.start.x,
          startY: scrollAdjustedY - flowline.start.y,
          endX: scrollAdjustedX - flowline.end.x,
          endY: scrollAdjustedY - flowline.end.y,
        };
      }
    });

    setOffsets(newOffsets); // Establecer offsets después de calcular
    scrollAreaRef.current.classList.add('dragging');
  };

  // Notifica las instancias seleccionadas
  const notifySelectedInstances = useCallback(
    (elements) => {
      const selectedInstanceIds = elements
        .flatMap((el) => el.instances)
        .filter((inst) => inst.seleccionado)
        .map((inst) => inst.instance_id);
      onInstancesSelected(selectedInstanceIds);
    },
    [onInstancesSelected]
  );

  const notifySelectedFlowlines = useCallback(
    (flowLines) => {
      const selectedFlowlineIds = flowLines.filter((line) => line.seleccionado).map((line) => line.id);
      onFlowlinesSelected(selectedFlowlineIds);
    },
    [onFlowlinesSelected]
  );

  // Maneja el evento de mouse down en un nodo
  const handleNodeMouseDown = (e, instance, pointId, x, y) => {
    e.stopPropagation();
    if (isHoveringFlowlineText) return;

    // Establecer el punto de inicio de la conexión a las coordenadas exactas del nodo
    setCurrentConnectingConnection({
      start: {
        instanceId: instance.instance_id,
        pointId,
        x: instance.anchor_coordinates.x + x,
        y: instance.anchor_coordinates.y + y,
      },
      end: null,
    });

    // Actualizar el estado seleccionado de las instancias
    const updatedElements = currentProject.elements.map((el) => {
      const updatedInstances = el.instances.map((inst) =>
        inst.instance_id === instance.instance_id
          ? { ...inst, seleccionado: true }
          : { ...inst, seleccionado: false }
      );
      return { ...el, instances: updatedInstances };
    });
    setCurrentProject((prev) => ({ ...prev, elements: updatedElements }));
    notifySelectedInstances(updatedElements);
  };

  // Maneja el evento de mouse enter en un nodo
  const handleNodeMouseEnter = (instance, pointId, x, y) => {
    if (currentConnectingConnection.start) {
      setHoveredNodePoint({
        instanceId: instance.instance_id,
        pointId,
        x: instance.anchor_coordinates.x + x,
        y: instance.anchor_coordinates.y + y,
      });
    } else {
      setHoveredNodePoint({ instanceId: instance.instance_id, pointId });
    }
  };

  const handleMouseMove = useCallback(
    (e) => {
      const snapToNearestNode = (x, y) => {
        const SNAP_THRESHOLD = 15;
        let nearestNode = null;
        let minDistance = SNAP_THRESHOLD;

        currentProject.elements.forEach((element) => {
          element.instances.forEach((instance) => {
            const shape = currentProject.icons[element.icon];
            shape.nodes.forEach((node) => {
              const nodeX = instance.anchor_coordinates.x + node.x;
              const nodeY = instance.anchor_coordinates.y + node.y;
              const distance = Math.sqrt((x - nodeX) ** 2 + (y - nodeY) ** 2);
              if (distance < minDistance) {
                minDistance = distance;
                nearestNode = {
                  instanceId: instance.instance_id,
                  pointId: `node-${node.id}`,
                  x: nodeX,
                  y: nodeY,
                };
              }
            });
          });
        });

        return nearestNode;
      };

      const updateSelectedElements = (rect) => {
        const selected = currentProject.elements.map((el) => {
          const updatedInstances = el.instances.map((inst) => {
            const instRect = {
              x: inst.anchor_coordinates.x,
              y: inst.anchor_coordinates.y,
              width: CELL_SIZE * 10,
              height: CELL_SIZE * 10,
            };
            const isSelected =
              rect.x < instRect.x + instRect.width &&
              rect.x + rect.width > instRect.x &&
              rect.y < instRect.y + instRect.height &&
              rect.y + rect.height > instRect.y;
            return { ...inst, seleccionado: isSelected };
          });
          return { ...el, instances: updatedInstances };
        });
        setCurrentProject((prev) => ({ ...prev, elements: selected }));
        notifySelectedInstances(selected);
      };

      const updateSelectedFlowlines = (rect) => {
        const selectedFlowlines = currentProject.flowLines.map((flowline) => {
          const midX = (flowline.start.x + flowline.end.x) / 2;
          const midY = (flowline.start.y + flowline.end.y) / 2;
          const isSelected =
            rect.x < midX &&
            rect.x + rect.width > midX &&
            rect.y < midY &&
            rect.y + rect.height > midY;
          return { ...flowline, seleccionado: isSelected };
        });
        setCurrentProject((prev) => ({ ...prev, flowLines: selectedFlowlines }));
        notifySelectedFlowlines(selectedFlowlines);
      };

      if (isDragging) {
        const scrollAdjustedX = e.clientX + scrollAreaRef.current.scrollLeft;
        const scrollAdjustedY = e.clientY + scrollAreaRef.current.scrollTop;

        const newElements = currentProject.elements?.map((el) => {
          const newInstances = el.instances?.map((inst) => {
            if (inst.seleccionado && offsets[inst.instance_id]) {
              let newX = scrollAdjustedX - offsets[inst.instance_id].x;
              let newY = scrollAdjustedY - offsets[inst.instance_id].y;

              // Siempre ajustar a la cuadrícula
              newX = Math.round(newX / CELL_SIZE) * CELL_SIZE;
              newY = Math.round(newY / CELL_SIZE) * CELL_SIZE;

              return { ...inst, anchor_coordinates: { x: newX, y: newY } };
            }
            return inst;
          });

          return { ...el, instances: newInstances };
        });

        const newFlowLines = currentProject.flowLines?.map((flowLine) => {
          const startInstance = newElements
            ?.flatMap((el) => el.instances)
            ?.find((inst) => inst.instance_id === flowLine.start.instanceId);
          const endInstance = newElements
            ?.flatMap((el) => el.instances)
            ?.find((inst) => inst.instance_id === flowLine.end.instanceId);

          const updatedFlowLine = { ...flowLine };

          if (flowLine.seleccionado && offsets[flowLine.id]) {
            const newStartX = scrollAdjustedX - offsets[flowLine.id].startX;
            const newStartY = scrollAdjustedY - offsets[flowLine.id].startY;
            const newEndX = scrollAdjustedX - offsets[flowLine.id].endX;
            const newEndY = scrollAdjustedY - offsets[flowLine.id].endY;

            // Lógica para la instancia de inicio
            if (startInstance?.seleccionado) {
              const parentElement = currentProject.elements.find((el) =>
                el.instances.some((inst) => inst.instance_id === startInstance.instance_id)
              );
              const icon = currentProject.icons[parentElement?.icon];
              const startNode = icon?.nodes.find(
                (node) => node.id === parseInt(flowLine.start.pointId.split('-')[1])
              );

              if (startNode) {
                updatedFlowLine.start.x =
                  startInstance.anchor_coordinates.x + startNode.x;
                updatedFlowLine.start.y =
                  startInstance.anchor_coordinates.y + startNode.y;
              }
            } else if (!flowLine.start.instanceId && !flowLine.start.pointId) {
              updatedFlowLine.start.x = newStartX;
              updatedFlowLine.start.y = newStartY;
            }

            // Lógica para la instancia final
            if (endInstance?.seleccionado) {
              const parentElement = currentProject.elements.find((el) =>
                el.instances.some((inst) => inst.instance_id === endInstance.instance_id)
              );
              const icon = currentProject.icons[parentElement?.icon];
              const endNode = icon?.nodes.find(
                (node) => node.id === parseInt(flowLine.end.pointId.split('-')[1])
              );

              if (endNode) {
                updatedFlowLine.end.x = endInstance.anchor_coordinates.x + endNode.x;
                updatedFlowLine.end.y = endInstance.anchor_coordinates.y + endNode.y;
              }
            } else if (!flowLine.end.instanceId && !flowLine.end.pointId) {
              updatedFlowLine.end.x = newEndX;
              updatedFlowLine.end.y = newEndY;
            }

            return updatedFlowLine;
          } else {
            // Casos cuando la flowline no está seleccionada

            if (startInstance?.seleccionado) {
              // Si la instancia de inicio está seleccionada, mover el punto de inicio de la flowline
              const parentElement = currentProject.elements.find((el) =>
                el.instances.some((inst) => inst.instance_id === startInstance.instance_id)
              );
              const icon = currentProject.icons[parentElement?.icon];
              const startNode = icon?.nodes.find(
                (node) => node.id === parseInt(flowLine.start.pointId.split('-')[1])
              );

              if (startNode) {
                updatedFlowLine.start.x =
                  startInstance.anchor_coordinates.x + startNode.x;
                updatedFlowLine.start.y =
                  startInstance.anchor_coordinates.y + startNode.y;
              }
            }

            if (endInstance?.seleccionado) {
              // Si la instancia final está seleccionada, mover el punto final de la flowline
              const parentElement = currentProject.elements.find((el) =>
                el.instances.some((inst) => inst.instance_id === endInstance.instance_id)
              );
              const icon = currentProject.icons[parentElement?.icon];
              const endNode = icon?.nodes.find(
                (node) => node.id === parseInt(flowLine.end.pointId.split('-')[1])
              );

              if (endNode) {
                updatedFlowLine.end.x = endInstance.anchor_coordinates.x + endNode.x;
                updatedFlowLine.end.y = endInstance.anchor_coordinates.y + endNode.y;
              }
            }

            // Si ni la instancia de inicio ni la final están seleccionadas, no mover la flowline
            return updatedFlowLine;
          }
        });

        setCurrentProject((prev) => ({
          ...prev,
          elements: newElements,
          flowLines: newFlowLines || prev.flowLines,
        }));
      } else if (currentConnectingConnection.start) {
        const svg = scrollAreaRef.current.querySelector('svg');
        const pt = svg.createSVGPoint();
        pt.x = e.clientX;
        pt.y = e.clientY;
        const cursorPoint = pt.matrixTransform(svg.getScreenCTM().inverse());

        const snappedNode = snapToNearestNode(cursorPoint.x, cursorPoint.y);

        if (snappedNode) {
          setCurrentConnectingConnection((prev) => ({
            ...prev,
            end: { ...snappedNode },
          }));
          // Establecer el nodo sobre el cual se hace hover
          setHoveredNodePoint({
            instanceId: snappedNode.instanceId,
            pointId: snappedNode.pointId,
            x: snappedNode.x,
            y: snappedNode.y,
          });
        } else {
          setCurrentConnectingConnection((prev) => ({
            ...prev,
            end: { x: cursorPoint.x, y: cursorPoint.y },
          }));
          // Resetear el nodo sobre el cual se hace hover si no hay snapping
          setHoveredNodePoint(null);
        }
      } else if (isSelecting) {
        const diagramOffset = scrollAreaRef.current.getBoundingClientRect();
        const scrollAdjustedX =
          e.clientX - diagramOffset.left + scrollAreaRef.current.scrollLeft;
        const scrollAdjustedY =
          e.clientY - diagramOffset.top + scrollAreaRef.current.scrollTop;
        const newRect = calculateSelectionRect(
          selectionStart.x,
          selectionStart.y,
          scrollAdjustedX,
          scrollAdjustedY
        );
        setSelectionRect(newRect);
        updateSelectedElements(newRect);
        updateSelectedFlowlines(newRect);
      }
    },
    [
      isDragging,
      currentProject.elements,
      offsets,
      currentConnectingConnection.start,
      isSelecting,
      selectionStart,
      //isGridActive, comentado por warning
      scrollAreaRef,
      currentProject.flowLines,
      currentProject.icons,
      setCurrentProject,
      notifySelectedFlowlines,
      notifySelectedInstances,
    ]
  );

  const handleMouseUp = (e) => {
    setIsDragging(false);
    scrollAreaRef.current.classList.remove('dragging');

    if (currentConnectingConnection.start && currentConnectingConnection.end) {
      const endPosition = hoveredNodePoint || currentConnectingConnection.end;

      const newFlowLine = {
        id: `flowline-${Date.now()}`,
        start: currentConnectingConnection.start,
        end: endPosition,
        seleccionado: false,
        name: `Flowline ${currentProject.flowLines.length + 1}`,
      };
      addOrModifyFlowLineInstanceInProject(newFlowLine);
    }

    setCurrentConnectingConnection({ start: null, end: null });
    setHoveredNodePoint(null);

    if (isSelecting) {
      setIsSelecting(false);
      setSelectionRect(null);
    }
  };

  const handleDiagramMouseDown = (e) => {
    if (e.button !== 0) return;

    const diagramOffset = scrollAreaRef.current.getBoundingClientRect();
    const scrollAdjustedX =
      e.clientX - diagramOffset.left + scrollAreaRef.current.scrollLeft;
    const scrollAdjustedY =
      e.clientY - diagramOffset.top + scrollAreaRef.current.scrollTop;

    if (!isGridActive) {
      setCurrentConnectingConnection({
        start: { instanceId: null, pointId: null, x: scrollAdjustedX, y: scrollAdjustedY },
        end: null,
      });
    }

    setSelectionStart({ x: scrollAdjustedX, y: scrollAdjustedY });
    setSelectionRect({ x: scrollAdjustedX, y: scrollAdjustedY, width: 0, height: 0 });
    setIsSelecting(true);
  };

  // Se ejecuta al seleccionar una flowline
  const handleFlowlineMouseDown = (e, flowline) => {
    e.stopPropagation();
    if (isSelecting) return;

    const updatedFlowlines = currentProject.flowLines.map((line) =>
      line.id === flowline.id ? { ...line, seleccionado: true } : { ...line, seleccionado: false }
    );
    setCurrentProject((prev) => ({ ...prev, flowLines: updatedFlowlines }));
    notifySelectedFlowlines(updatedFlowlines);
  };

  // Ajusta los endpoints de la flowline para tener un offset perpendicular de 5px desde el nodo
  // Dentro de Diagram.js

  function adjustFlowlineEndpoint(flowlineEndpoint, oppositeEndpoint) {
    if (flowlineEndpoint.instanceId && flowlineEndpoint.pointId) {
      // Obtener la instancia
      const instance = currentProject.elements
        .flatMap((element) => element.instances)
        .find((inst) => inst.instance_id === flowlineEndpoint.instanceId);
      if (!instance)
        return { actual: flowlineEndpoint, adjusted: flowlineEndpoint }; // No se puede encontrar la instancia
  
      // Obtener el elemento
      const element = currentProject.elements.find((el) =>
        el.instances.some((inst) => inst.instance_id === flowlineEndpoint.instanceId)
      );
      if (!element)
        return { actual: flowlineEndpoint, adjusted: flowlineEndpoint };
  
      const shape = currentProject.icons[element.icon];
      if (!shape)
        return { actual: flowlineEndpoint, adjusted: flowlineEndpoint };
  
      // Obtener el nodo
      const nodeId = parseInt(flowlineEndpoint.pointId.split('-')[1]);
      const node = shape.nodes.find((n) => n.id === nodeId);
      if (!node)
        return { actual: flowlineEndpoint, adjusted: flowlineEndpoint };
  
      // Posición absoluta del nodo
      const nodeX = instance.anchor_coordinates.x + node.x;
      const nodeY = instance.anchor_coordinates.y + node.y;
  
      // Calcular el bounding box (rectángulo delimitador) de la instancia
      const instanceMinX = instance.anchor_coordinates.x;
      const instanceMinY = instance.anchor_coordinates.y;
      const instanceMaxX = instanceMinX + CELL_SIZE * NUM_CELLS_FIGURE_WIDTH;
      const instanceMaxY = instanceMinY + CELL_SIZE * NUM_CELLS_FIGURE_WIDTH;
  
      // Calcular la dirección desde el nodo hacia afuera
      let dirX = 0;
      let dirY = 0;
  
      // Calcular distancias a los bordes
      const distLeft = nodeX - instanceMinX;
      const distRight = instanceMaxX - nodeX;
      const distTop = nodeY - instanceMinY;
      const distBottom = instanceMaxY - nodeY;
  
      // Determinar el borde más cercano
      const minDist = Math.min(distLeft, distRight, distTop, distBottom);
  
      if (minDist === distLeft) {
        dirX = -1;
        dirY = 0;
      } else if (minDist === distRight) {
        dirX = 1;
        dirY = 0;
      } else if (minDist === distTop) {
        dirX = 0;
        dirY = -1;
      } else if (minDist === distBottom) {
        dirX = 0;
        dirY = 1;
      }
  
      // Calcular la distancia desde el nodo hasta el borde en esa dirección
      let distanceToEdge = minDist;
  
      // Extender 5px más allá del borde
      const edgeExtension = 5;
      const totalDistance = distanceToEdge + edgeExtension;
  
      // Calcular el offset
      const offsetX = dirX * totalDistance;
      const offsetY = dirY * totalDistance;
  
      // Ajustar posición
      return {
        actual: { ...flowlineEndpoint, x: nodeX, y: nodeY },
        adjusted: { ...flowlineEndpoint, x: nodeX + offsetX, y: nodeY + offsetY },
      };
  
    } else {
      // Caso cuando el endpoint no está conectado a un nodo
      // Puedes mantener el código existente
      const { x: x1, y: y1 } = flowlineEndpoint;
      const { x: x2, y: y2 } = oppositeEndpoint;
  
      const dirX = x2 - x1;
      const dirY = y2 - y1;
  
      const length = Math.sqrt(dirX * dirX + dirY * dirY);
      if (length === 0)
        return { actual: flowlineEndpoint, adjusted: flowlineEndpoint };
  
      const normX = dirX / length;
      const normY = dirY / length;
  
      const offsetX = normX * 15;
      const offsetY = normY * 15;
  
      return {
        actual: flowlineEndpoint,
        adjusted: { ...flowlineEndpoint, x: x1 + offsetX, y: y1 + offsetY },
      };
    }
  }

  const calculateFlowlinePath = (startEndpoint, endEndpoint) => {
  const { actual: actualStart, adjusted: adjustedStart } = adjustFlowlineEndpoint(
    startEndpoint,
    endEndpoint
  );
  const { actual: actualEnd, adjusted: adjustedEnd } = adjustFlowlineEndpoint(
    endEndpoint,
    startEndpoint
  );

    // Convertir coordenadas a índices de la cuadrícula
    const gridStartX = Math.floor(adjustedStart.x / CELL_SIZE);
    const gridStartY = Math.floor(adjustedStart.y / CELL_SIZE);
    const gridEndX = Math.floor(adjustedEnd.x / CELL_SIZE);
    const gridEndY = Math.floor(adjustedEnd.y / CELL_SIZE);

    // Crear la cuadrícula
    const gridWidth = DIAGRAM_WIDTH / CELL_SIZE;
    const gridHeight = DIAGRAM_HEIGHT / CELL_SIZE;

    const matrix = new Array(gridHeight).fill(0).map(() => new Array(gridWidth).fill(0));

    // Marcar las instancias como obstáculos en la matriz
    currentProject.elements.forEach((element) => {
      element.instances.forEach((instance) => {
        const instX = Math.floor(instance.anchor_coordinates.x / CELL_SIZE);
        const instY = Math.floor(instance.anchor_coordinates.y / CELL_SIZE);
        const instWidth = Math.ceil((CELL_SIZE * NUM_CELLS_FIGURE_WIDTH) / CELL_SIZE);
        const instHeight = Math.ceil((CELL_SIZE * NUM_CELLS_FIGURE_WIDTH) / CELL_SIZE);

        for (let y = instY - MARGIN; y < instY + instHeight + MARGIN; y++) {
          for (let x = instX - MARGIN; x < instX + instWidth + MARGIN; x++) {
            if (x >= 0 && x < gridWidth && y >= 0 && y < gridHeight) {
              matrix[y][x] = 1; // Obstáculo
            }
          }
        }
      });
    });

    // Asegurar que los puntos de inicio y fin están dentro de los límites
    const adjustPointInGrid = (x, y) => {
      return {
        x: Math.max(0, Math.min(x, gridWidth - 1)),
        y: Math.max(0, Math.min(y, gridHeight - 1)),
      };
    };

    const startPoint = adjustPointInGrid(gridStartX, gridStartY);
    const endPoint = adjustPointInGrid(gridEndX, gridEndY);

    // Marcar los puntos de inicio y fin como transitables
    matrix[startPoint.y][startPoint.x] = 0;
    matrix[endPoint.y][endPoint.x] = 0;

    // Crear la cuadrícula de PathFinding
    const grid = new PF.Grid(matrix);

    // Usar el buscador de caminos
    const finder = new PF.AStarFinder({
      allowDiagonal: true,
      dontCrossCorners: true,
      heuristic: PF.Heuristic.manhattan,
    });

    // Encontrar el camino
    const path = finder.findPath(startPoint.x, startPoint.y, endPoint.x, endPoint.y, grid);

    // Si no se encuentra un camino, usar una línea recta
    if (path.length === 0) {
      return [actualStart, adjustedStart, adjustedEnd, actualEnd];
    }

    // Convertir el camino a coordenadas
    const pathInCoords = path.map(([x, y]) => ({
      x: x * CELL_SIZE + CELL_SIZE / 2,
      y: y * CELL_SIZE + CELL_SIZE / 2,
    }));

    // Añadir los puntos ajustados al inicio y al final
    pathInCoords.unshift(actualStart);
    pathInCoords.push(actualEnd);

    return pathInCoords;
  };

  const renderConnections = () => {
    return currentProject.flowLines.map((conn, index) => {
      const path = calculateFlowlinePath(conn.start, conn.end);
  
      // Calcular la longitud total del flowline
      const totalLength = path.reduce((acc, curr, i) => {
        if (i === 0) return 0;
        const dx = path[i].x - path[i - 1].x;
        const dy = path[i].y - path[i - 1].y;
        return acc + Math.sqrt(dx * dx + dy * dy);
      }, 0);
  
      let textWidth = 60; // Ajusta este valor según tus necesidades
  
      // Encontrar la posición para el texto en el punto medio del flowline
      let accumulatedLength = 0;
      let labelPosition = null;
      for (let i = 0; i < path.length - 1; i++) {
        const point = path[i];
        const nextPoint = path[i + 1];
        const segmentLength = Math.sqrt(
          (nextPoint.x - point.x) ** 2 + (nextPoint.y - point.y) ** 2
        );
  
        if (!labelPosition && accumulatedLength + segmentLength >= totalLength / 2) {
          // Calcular la posición exacta en el segmento
          const remainingLength = (totalLength / 2) - accumulatedLength;
          const ratio = remainingLength / segmentLength;
          const labelX = point.x + ratio * (nextPoint.x - point.x);
          const labelY = point.y + ratio * (nextPoint.y - point.y);
          const angle = Math.atan2(nextPoint.y - point.y, nextPoint.x - point.x) * (180 / Math.PI);
          const textRotation = angle > 90 || angle < -90 ? 180 : 0;
  
          labelPosition = {
            x: labelX,
            y: labelY,
            angle: angle,
            textRotation: textRotation,
          };
          break;
        }
  
        accumulatedLength += segmentLength;
      }
  
      return (
        <g key={index}>
          {/* Renderizar todas las líneas primero */}
          {path.slice(0, -1).map((point, i) => {
            const nextPoint = path[i + 1];
            return (
              <line
                key={`line-segment-${index}-${i}`}
                className={`arrow ${conn.seleccionado ? 'selected' : ''}`}
                x1={point.x}
                y1={point.y}
                x2={nextPoint.x}
                y2={nextPoint.y}
                markerEnd={i === path.length - 2 ? 'url(#arrowhead)' : ''}
                onMouseDown={(e) => handleFlowlineMouseDown(e, conn)}
              />
            );
          })}
  
          {/* Renderizar el texto después de las líneas */}
          {labelPosition && (
            <g
              className="flowline-label-group"
              transform={`translate(${labelPosition.x}, ${labelPosition.y}) rotate(${labelPosition.angle})`}
            >
              <foreignObject
                x={-textWidth / 2}
                y={-17}
                width={textWidth}
                height="20px"
                style={{
                  overflow: 'visible',
                  textAlign: 'center',
                  pointerEvents: 'none',
                }}
              >
                <div
                  className="flowline-text-container"
                  xmlns="http://www.w3.org/1999/xhtml"
                  style={{
                    display: 'inline-block',
                    whiteSpace: 'nowrap',
                    maxWidth: `${textWidth}px`,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    padding: '0 5px',
                    transform: `rotate(${labelPosition.textRotation}deg)`,
                    cursor: 'pointer',
                    pointerEvents: 'auto',
                  }}
                  onClick={(e) => {
                    e.stopPropagation(); // Evitar propagación del evento
                    const diagramOffset = scrollAreaRef.current.getBoundingClientRect();
                    const x = e.clientX - diagramOffset.left;
                    const y = e.clientY - diagramOffset.top;
                    onFlowlineClick(conn, { x, y });
                  }}
                  onMouseEnter={() => setIsHoveringFlowlineText(true)}
                  onMouseLeave={() => setIsHoveringFlowlineText(false)}
                >
                  {conn.name}
                </div>
              </foreignObject>
            </g>
          )}
        </g>
      );
    });
  };
  
  const renderGrid = () => {
    const lines = [];
    for (let i = 0; i <= DIAGRAM_WIDTH; i += CELL_SIZE) {
      lines.push(<line key={`v-${i}`} x1={i} y1="0" x2={i} y2={DIAGRAM_HEIGHT} stroke="#ccc" />);
    }
    for (let i = 0; i <= DIAGRAM_HEIGHT; i += CELL_SIZE) {
      lines.push(<line key={`h-${i}`} x1="0" y1={i} x2={DIAGRAM_WIDTH} y2={i} stroke="#ccc" />);
    }
    return lines;
  };

  const generateNodePoints = (instance, shape) => {
    if (!shape || !shape.nodes || !shape.edges) {
      if (process.env.REACT_APP_NODE_ENV !== 'production') {
        console.warn('Shape is missing nodes or edges:', shape);
      }
      
      return null;
    }
  
    const nodeIdToPortName = {};
    for (const [portName, nodeRef] of Object.entries(shape.ports || {})) {
      const nodeId = parseInt(nodeRef.split('-')[1], 10);
      nodeIdToPortName[nodeId] = portName;
    }
  
    const edges = shape.edges
      .map((edge, index) => {
        const startNode = shape.nodes.find((node) => node.id === edge.beginning);
        const endNode = shape.nodes.find((node) => node.id === edge.end);
        if (!startNode || !endNode) return null;
        return (
          <line
            key={`edge-${index}-${instance.instance_id}`}
            className="shape-edge"
            x1={startNode.x}
            y1={startNode.y}
            x2={endNode.x}
            y2={endNode.y}
            stroke="white"
            strokeWidth="1px"
          />
        );
      })
      .filter((edge) => edge !== null);
  
    const calculateTextWidth = (text, font = "12px Arial") => {
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");
      context.font = font;
      return context.measureText(text).width + 10; // Añadir margen
    };
  
    const points = shape.nodes.map((node) => {
      const isHovered =
        hoveredNodePoint &&
        hoveredNodePoint.instanceId === instance.instance_id &&
        hoveredNodePoint.pointId === `node-${node.id}`;
  
      const portName = nodeIdToPortName[node.id];
      const textWidth = calculateTextWidth(portName);
  
      return (
        <g key={`node-group-${node.id}-${instance.instance_id}`}>
          <circle
            className={`node-point ${isHovered ? 'hovered' : ''}`}
            cx={node.x}
            cy={node.y}
            r={NODE_RADIUS * 1.5}
            fill="yellow"
            strokeWidth="1"
            onMouseDown={(e) => handleNodeMouseDown(e, instance, `node-${node.id}`, node.x, node.y)}
            onMouseEnter={() => handleNodeMouseEnter(instance, `node-${node.id}`, node.x, node.y)}
            onMouseLeave={() => setHoveredNodePoint(null)}
          />
          {
            // Renderizar el nombre del puerto si está hovered y es un puerto con nombre
            isHovered && portName && (
              <>
                <rect
                  x={node.x + 9}
                  y={node.y - 15}
                  width={textWidth}
                  height="20"
                  fill="#262626"
                  opacity="0.8"
                  rx="4"
                  ry="4"
                />
                <text
                  className="port-name"
                  x={node.x + 13}
                  y={node.y} 
                  fill="white"
                  fontSize="12"
                  pointerEvents="none"
                >
                  {portName}
                </text>
              </>
            )
          }
        </g>
      );
    });
  
    return [...edges, ...points];
  };
  
  

  return (
    <div
      className="diagram-scroll-area"
      ref={scrollAreaRef}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onMouseDown={handleDiagramMouseDown}
    >
      <svg className="diagram" ref={drop} width={DIAGRAM_WIDTH} height={DIAGRAM_HEIGHT}>
      <defs>
        <marker
            id="arrowhead"
            markerWidth="10"
            markerHeight="7"
            refX="10"         // Cambiado de 5 a 10
            refY="3.5"
            orient="auto"
          >
            <polygon points="0 0, 10 3.5, 0 7" fill="#f6f6f6" />
          </marker>
        </defs>
        {renderGrid()}
        {!isGridActive}
        {currentProject.elements.flatMap((element) =>
          element.instances.map((instance) => {
            const shape = currentProject.icons[element.icon];
            return (
              <g
                key={instance.instance_id}
                transform={`translate(${instance.anchor_coordinates.x}, ${instance.anchor_coordinates.y})`}
                onMouseDown={(e) => handleMouseDown(e, instance)}
                className={`instance ${instance.seleccionado ? 'selected' : ''}`}
              >
                <rect
                  x={-CELL_SIZE * 1.5}
                  y={-CELL_SIZE * 1.5}
                  width={CELL_SIZE * NUM_CELLS_FIGURE_WIDTH * 1.2}
                  height={CELL_SIZE * NUM_CELLS_FIGURE_WIDTH * 1.2}
                  fill="transparent"
                  stroke={instance.seleccionado ? '#a8d3e8' : 'none'}
                  strokeWidth={instance.seleccionado ? 2 : 1}
                />
                <foreignObject
                  x={shape.name_coords.x} // Usar coordenadas ajustadas o por defecto
                  y={shape.name_coords.y}
                  width={CELL_SIZE * NUM_CELLS_FIGURE_WIDTH} 
                  height={CELL_SIZE * NUM_CELLS_FIGURE_WIDTH} 
                >
                  <div className="element-content">{element.name}</div>
                </foreignObject>
                {generateNodePoints(instance, shape)}
              </g>
            );
          })
        )}

        {renderConnections()}
        {currentConnectingConnection.start && currentConnectingConnection.end && (
          <g>
            {calculateFlowlinePath(currentConnectingConnection.start, currentConnectingConnection.end).map(
              (point, index, arr) => {
                if (index === arr.length - 1) return null;
                const nextPoint = arr[index + 1];
                return (
                  <line
                    key={`temp-line-${index}`}
                    className="arrow"
                    x1={point.x}
                    y1={point.y}
                    x2={nextPoint.x}
                    y2={nextPoint.y}
                    markerEnd={index === arr.length - 2 ? 'url(#arrowhead)' : ''}
                  />
                );
              }
            )}
          </g>
        )}
        {selectionRect && (
          <rect
            className="selection-rect"
            x={selectionRect.x}
            y={selectionRect.y}
            width={selectionRect.width}
            height={selectionRect.height}
            fill="rgba(0, 0, 255, 0.3)"
            stroke="blue"
          />
        )}
      </svg>
    </div>
  );
};

const calculateSelectionRect = (startX, startY, currentX, currentY) => {
  return {
    x: Math.min(startX, currentX),
    y: Math.min(startY, currentY),
    width: Math.abs(currentX - startX),
    height: Math.abs(currentY - startY),
  };
};

export default Diagram;
