// DropdownMenu.js
import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { Dropdown, Modal } from 'react-bootstrap'; //removido buton por warning
import './DropdownMenu.css';
import { useNavigate } from 'react-router-dom';

const PortalDropdownMenu = ({ children }) => {
  return ReactDOM.createPortal(children, document.body);
};

const DropdownMenu = ({
  userName,
  userEmail,
  handleLogout,
  menuDropdownVisible,
  handleToggleMenuDropdown,
  resetSelections,
}) => {
  const [showPlanModal, setShowPlanModal] = useState(false);
  const [isMenuClicked, setIsMenuClicked] = useState(false);

  const handleMenuClick = () => {
    setIsMenuClicked(true);
    handleToggleMenuDropdown();
    resetSelections();
  };

  const navigate = useNavigate();

  const handleConfigurationClick = () => {
    navigate('/')
    //cambio de ruta
  };


  const handlePlanClick = () => setShowPlanModal(true);
  const handleClosePlanModal = () => {
    setShowPlanModal(false);
    setIsMenuClicked(false);
  };

  return (
    <>
      <Dropdown show={menuDropdownVisible} onToggle={handleToggleMenuDropdown}>
        <Dropdown.Toggle
          as="div"
          id="dropdown-basic-button"
          className="menu-button"
          onClick={handleMenuClick}
        >
          <i className="bi bi-person-lines-fill"></i>
        </Dropdown.Toggle>
        <PortalDropdownMenu>
          <Dropdown.Menu
            className={
              isMenuClicked ? 'dropdown-menu menu-clicked' : 'dropdown-menu'
            }
          >
            <div className="dropdown-header">
              <p className="greeting">Hola, {userName}!</p>
            </div>
            <div className="dropdown-body">
              <p className="email">{userEmail}</p>
            </div>
            <Dropdown.Divider />
            <Dropdown.Item
              className={
                isMenuClicked
                  ? 'dropdown-item menu-item-clicked'
                  : 'dropdown-item'
              }
              onClick={handlePlanClick}
            >
              <i
                className="bi bi-graph-up-arrow"
                style={{ marginRight: '8px' }}
              ></i>{' '}
              My plan
            </Dropdown.Item>
            <Dropdown.Item
              className={
                isMenuClicked
                  ? 'dropdown-item menu-item-clicked'
                  : 'dropdown-item'
              }
              onClick={handleConfigurationClick}
            >
              <i className="bi bi-gear" style={{ marginRight: '8px' }}></i>{' '}
              Configuration
            </Dropdown.Item>
            <Dropdown.Item
              className={
                isMenuClicked
                  ? 'dropdown-item menu-item-clicked'
                  : 'dropdown-item'
              }
            >
              <i
                className="bi bi-building-gear"
                style={{ marginRight: '8px' }}
              ></i>{' '}
              Ejemplo
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item
              className={
                isMenuClicked
                  ? 'dropdown-item menu-item-clicked'
                  : 'dropdown-item'
              }
              onClick={handleLogout}
            >
              <i
                className="bi bi-box-arrow-left"
                style={{ marginRight: '8px' }}
              ></i>{' '}
              Logout
            </Dropdown.Item>
          </Dropdown.Menu>
        </PortalDropdownMenu>
      </Dropdown>

      {/* Modal para "My Plan" */}
      <Modal show={showPlanModal} onHide={handleClosePlanModal} centered>
        {/* ...contenido existente del modal "My Plan"... */}
      </Modal>

      
    </>
  );
};

export default DropdownMenu;
