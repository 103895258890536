import React, { createContext, useContext, useState, useEffect, useMemo, useCallback } from 'react';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
//import { predefinedNodes } from '../components/GroupFocus/nodes';
import { useLocation } from 'react-router-dom';

export const ProjectContext = createContext();

export const useMyContext = () => useContext(ProjectContext);

// Definir flattenIcons para aplanar los nodos de predefinedNodes en un solo nivel
const flattenIcons = (nodes) => {
    let flatIcons = {};
    for (const category in nodes) {
        for (const nodeKey in nodes[category].nodes) {
            flatIcons[nodeKey] = nodes[category].nodes[nodeKey];
        }
    }
    return flatIcons;
};

export const ProjectProvider = ({ children }) => {
    const [projects, setProjects] = useState([]);
    const [currentProject, setCurrentProject] = useState({ 
        elements: [],
        flowLines: [],
        icons: {}, // Asegúrate de que los icons se inicialicen correctamente
        messages: [],
        simBlocks: [],
        diagramRemaining: {},
        conversationId: "",
        id: null,
        key: null,
        key_d: null,
        key_c: null,
        sub: null,
        projectName: 'New Chat',
        lastModelUsed: null,
        seleccionado: false,
        canModifyDiagram: true
    });


    const [loading, setLoading] = useState(false);

    const [currentSub, setCurrentSub] = useState(null);
    const [defaultModel, setDefaultModel] = useState("");
    const [defaultDiagram, setDefaultDiagram] = useState({});
    const [defaultMessages, setDefaultMessages] = useState([]);
    const [defaultSimBlocks, setDefaultSimBlocks] = useState([]);
    const [defaultMessage, setDefaultMessage] = useState(""); //this one is for real time sending
    const [availableModels, setAvailableModels] = useState([]);
    const [id, setId] = useState(null);
    //const [reactAppStreamApi, setReactAppStreamApi] = useState(process.env.REACT_APP_STREAM_API);
    //const [reactAppNoStreamApi, setReactAppNoStreamApi] = useState(process.env.REACT_APP_NO_STREAM_API);

    const [predefinedNodes, setPredefinedNodes] = useState("")
    const [productSpecificationsSelections, setProductSpecificationsSelections] = useState({
        condenserType: {
            selected: 'copperCondenser', // Represents the selected `node_type` for the condenser
            options: [
                { node_type: '3_Tube_Shotgun_Condenser', displayText: '3 Tube Shotgun Condenser' },
                { node_type: '5_Tube_Shotgun_Condenser', displayText: '5 Tube Shotgun Condenser' }
            ]
        },
        heaterSystem: {
            selected: 'electricHeater2000W',
            options: [
                { node_type: '2000W Heater', displayText: '2000W Heater' },
                { node_type: '2500W Heater', displayText: '2500W Heater' }
            ]
        },
        distillerVolume: {
            selected: 'volume1',
            options: [
                { node_type: '5_Gallon_Batch_Distiller', displayText: '5 Gallon Batch Distiller' },
                { node_type: '10_Gallon_Batch_Distiller', displayText: '10 Gallon Batch Distiller' }
            ]
        }
    });
    
    

    const location = useLocation();
    const [initialized, setInitialized] = useState(false);  // Track whether initialization has happened

    const fetchPredefinedNodes = useCallback(async (sub_param, id_param) => {
        
        try {
            const response = await axios.post(process.env.REACT_APP_NO_STREAM_API, { call: 7, sub: sub_param, id: id_param });
            setPredefinedNodes(response.data.predefined_nodes);
        } catch (error) {
            if (process.env.REACT_APP_NODE_ENV !== 'production') {
                console.error('Error fetching predefined nodes:', error);
            }
            
        }
        
    }, []);

    const getUser = useCallback(async () => {
        try {
            const response = await axios.post(process.env.REACT_APP_NO_STREAM_API, { call: 0, id: id });
            setCurrentSub(response.data.sub);
            setDefaultMessages(response.data.default_messages);
            setDefaultDiagram(response.data.default_diagram);
            setDefaultSimBlocks(response.data.default_simblocks);
            return {
                sub_param: response.data.sub, 
                default_messages: response.data.default_messages,
                default_diagram: response.data.default_diagram, 
                default_simblocks: response.data.default_simblocks
            };
        } catch (error){
            if (process.env.REACT_APP_NODE_ENV !== 'production') {
                console.log("Error retrieving user")
            }
            
        }
    },[id]);



    const fetchProjects = useCallback(async (shouldFetchPredefinedNodes = false, sub_param, id_param) => {
        try {
            const response = await axios.post(process.env.REACT_APP_NO_STREAM_API, { call: 1, sub: sub_param, id: id_param });
            const fetchedProjects = response.data.conversations_history.map(project => ({
                projectName: project.project_name,
                conversationId: project.conversation_id,
                id: id_param,
                date: project.date_created
            }));

            setProjects(fetchedProjects.reverse());
            
            setDefaultModel(response.data.default_model);
            setDefaultMessage(response.data.default_message);
            setAvailableModels(response.data.available_models);

            // Conditionally fetch predefined nodes if shouldFetchPredefinedNodes is true
            if (shouldFetchPredefinedNodes) {
                await fetchPredefinedNodes(response.data.sub, response.data.id);
            } 

            return response.data.default_model

        } catch (error) {
            if (process.env.REACT_APP_NODE_ENV !== 'production') {
                console.error('Error fetching projects: ', error);
            }
            
        }
    },[fetchPredefinedNodes]);

    const addOrModifySimBlockInProjectAndSave = async (newSimBlock) => {
        let updatedProject;
        setCurrentProject((prevProject) => {
            const simBlockIndex = prevProject.simBlocks.findIndex(sb => sb.id === newSimBlock.id);
    
            if (simBlockIndex !== -1) {
                // Update existing simBlock
                const updatedSimBlocks = [...prevProject.simBlocks];
                updatedSimBlocks[simBlockIndex] = newSimBlock;
                updatedProject = {
                    ...prevProject,
                    simBlocks: updatedSimBlocks
                };
            } else {
                // Add new simBlock without instances
                const simBlockWithNoInstances = {
                    ...newSimBlock,
                    instances: [] // Ensure instances are empty when the simBlock is first created
                };
                updatedProject = {
                    ...prevProject,
                    simBlocks: [...prevProject.simBlocks, simBlockWithNoInstances]
                };
            }
    
            return updatedProject; // Update the current project state
        });
    
        // Ensure saveProject is called after state update
        setTimeout(async () => {
            await saveProject(updatedProject); // Call saveProject with the updated project
            fetchProjects(false, currentSub, id);
        }, 10);
    };

    const deleteSimBlockInProjectAndSave = async (simBlockId) => {
        let updatedProject;
        
        setCurrentProject((prevProject) => {
            const updatedSimBlocks = prevProject.simBlocks.filter(sb => sb.id !== simBlockId);
    
            // Update the project with the remaining simBlocks
            updatedProject = {
                ...prevProject,
                simBlocks: updatedSimBlocks
            };
    
            return updatedProject; // Update the current project state
        });
    
        // Ensure saveProject is called after state update
        setTimeout(async () => {
            await saveProject(updatedProject); // Call saveProject with the updated project
            fetchProjects(false, currentSub, id);
        }, 10);
    };
    
    

    const addOrModifyElementinProjectAndSave = async (newElement) => {
        let updatedProject;
        
        // Obtener los íconos aplanados
        const flatIcons = flattenIcons(predefinedNodes);

    
        setCurrentProject((prevProject) => {
            const elementIndex = prevProject.elements.findIndex(el => el.id === newElement.id);
    
            if (elementIndex !== -1) {
                // Actualizar el elemento existente
                const updatedElements = [...prevProject.elements];
                updatedElements[elementIndex] = newElement;
                updatedProject = {
                    ...prevProject,
                    elements: updatedElements
                };
            } else {
                // Agregar nuevo elemento sin instancias
                const elementWithNoInstances = {
                    ...newElement,
                    instances: [] // Asegúrate de que las instancias estén vacías al crear el elemento
                };
    
                // Verificar si hay un ícono correspondiente para el nuevo elemento en los íconos predefinidos
                const newIcon = flatIcons[newElement.icon]; // Aquí asumimos que newElement tiene un campo 'type'
    
                // Solo agregar el ícono si existe
                const updatedIcons = newIcon
                    ? { ...prevProject.icons, [newElement.icon]: newIcon }
                    : { ...prevProject.icons };
    
                updatedProject = {
                    ...prevProject,
                    elements: [...prevProject.elements, elementWithNoInstances],
                    icons: updatedIcons // Actualizar icons en el proyecto
                };
            }
    
            return updatedProject; // Actualizar el estado del proyecto
        });
    
        // Asegúrate de guardar el proyecto actualizado
        setTimeout(async () => {
            await saveProject(updatedProject); // Llamar a saveProject con el proyecto actualizado
            fetchProjects(false, currentSub, id); // Actualizar la lista de proyectos
        }, 10);
    };

    const deleteElementInProjectAndSave = (elementId) => {
        setCurrentProject((prevProject) => {
            // Filter out the element to be deleted by its ID
            const elementToDelete = prevProject.elements.find(el => el.id === elementId);
            const instancesToDelete = elementToDelete ? elementToDelete.instances.map(inst => inst.instance_id) : [];
    
            // Create the updated project object without the deleted element and its instances
            const updatedElements = prevProject.elements.filter(el => el.id !== elementId);
            const updatedFlowLines = prevProject.flowLines.filter(
                flowLine => !instancesToDelete.includes(flowLine.start.instanceId) && !instancesToDelete.includes(flowLine.end.instanceId)
            );
    
            const updatedProject = {
                ...prevProject,
                elements: updatedElements,
                flowLines: updatedFlowLines
            };
    
            // Call saveProject with the updated project to persist changes
            saveProject(updatedProject);
            fetchProjects(false, currentSub, id);  // Optionally refresh the list of projects if needed
    
            return updatedProject;  // Update the current project state
        });
    };

    const addOrModifyElementInstanceInProject = (newElementInstance, parentElementId) => {
        setCurrentProject((prevProject) => {
            // Find the parent element by id
            const elementIndex = prevProject.elements.findIndex(el => el.id === parentElementId);
            if (elementIndex === -1) {
                if (process.env.REACT_APP_NODE_ENV !== 'production') {
                    console.error('Parent element not found');
                }
                
                return prevProject;
            }
    
            const element = prevProject.elements[elementIndex];
            const instanceIndex = element.instances.findIndex(instance => instance.instance_id === newElementInstance.instance_id);
    
            let updatedInstances;
            if (instanceIndex !== -1) {
                // Update existing instance
                updatedInstances = [...element.instances];
                updatedInstances[instanceIndex] = newElementInstance;
            } else {
                // Add new instance
                updatedInstances = [...element.instances, newElementInstance];
            }
    
            // Create updated element with updated instances
            const updatedElement = {
                ...element,
                instances: updatedInstances
            };
    
            // Create updated elements array
            const updatedElements = [...prevProject.elements];
            updatedElements[elementIndex] = updatedElement;
    
            // Create the updated project object
            const updatedProject = {
                ...prevProject,
                elements: updatedElements
            };
    
            return updatedProject; // Update the current project state
        });
    };

    const addOrModifyFlowLineInstanceInProject = (newFlowLine) => {
        setCurrentProject((prevProject) => {
            const flowLines = prevProject.flowLines || [];
    
            // Find the index of the flow line to be updated, if it exists
            const flowLineIndex = flowLines.findIndex(fl => fl.id === newFlowLine.id);
            let updatedProject;
    
            if (flowLineIndex !== -1) {
                // Update existing flow line
                const updatedFlowLines = [...flowLines];
                updatedFlowLines[flowLineIndex] = newFlowLine;
                updatedProject = {
                    ...prevProject,
                    flowLines: updatedFlowLines
                };
            } else {
                // Add new flow line
                updatedProject = {
                    ...prevProject,
                    flowLines: [...flowLines, newFlowLine]
                };
            }
    
            // Update the flow_lines array in the start and end instances
            const updatedElements = updatedProject.elements.map((element) => {
                const updatedInstances = element.instances.map((instance) => {
                    if (instance.instance_id === newFlowLine.start.instanceId || instance.instance_id === newFlowLine.end.instanceId) {
                        return {
                            ...instance,
                            flow_lines: [...instance.flow_lines, newFlowLine.id]
                        };
                    }
                    return instance;
                });
                return {
                    ...element,
                    instances: updatedInstances
                };
            });
    
            updatedProject = {
                ...updatedProject,
                elements: updatedElements
            };
    
            return updatedProject;
        });
    };

    const deleteElementInstanceAndAttachedFlowLinesInProject = (instanceId, parentElementId) => {
        setCurrentProject((prevProject) => {
    
            const elementIndex = prevProject.elements.findIndex(el => el.id === parentElementId);
            if (elementIndex === -1) {
                return prevProject;
            }
    
            const element = prevProject.elements[elementIndex];
    
            // Filter out the instance from the targeted element
            const updatedInstances = element.instances.filter(instance => instance.instance_id !== instanceId);
    
            // Filter out any flow lines attached to the instance
            const updatedFlowLines = prevProject.flowLines.filter(
                flowLine => flowLine.start.instanceId !== instanceId && flowLine.end.instanceId !== instanceId
            );
    
            // Update elements, setting the updated instances only for the targeted element
            const updatedElements = prevProject.elements.map(el => {
                if (el.id === parentElementId) {
                    return { ...el, instances: updatedInstances };
                } else {
                    return el;
                }
            });
    
            const updatedProject = {
                ...prevProject,
                elements: updatedElements,
                flowLines: updatedFlowLines
            };
    
            return updatedProject;
        });
    };

    const deleteFlowlineInstanceInProject = (flowlineId) => {
        setCurrentProject((prevProject) => {
            // Find and remove the flowline with the given ID
            const updatedFlowLines = prevProject.flowLines.filter(flowLine => flowLine.id !== flowlineId);
    
            // Remove the reference to the flowline from the instances
            const updatedElements = prevProject.elements.map(element => {
                const updatedInstances = element.instances.map(instance => {
                    const updatedFlowLines = instance.flow_lines.filter(lineId => lineId !== flowlineId);
                    return {
                        ...instance,
                        flow_lines: updatedFlowLines
                    };
                });
                return {
                    ...element,
                    instances: updatedInstances
                };
            });
    
            // Return the updated project state
            const updatedProject = {
                ...prevProject,
                elements: updatedElements,
                flowLines: updatedFlowLines
            };
    
            return updatedProject;
        });
    };

    const loadNewProject = useCallback(async (sub_param, id_param, default_messages, default_model, default_diagram, default_simblocks) => {
        try {
    
            const newCurrentProject = {
                elements: default_diagram.args.elements,
                flowLines: default_diagram.args.flow_lines,
                icons: default_diagram.args.icons,
                messages: default_messages,
                simBlocks: default_simblocks,
                diagramRemaining: {},
                conversationId: uuidv4(),
                id: id_param,
                key: null,
                key_d: null,
                key_c: null,
                sub: sub_param,
                projectName: 'New Chat!',
                lastModelUsed: default_model,
                seleccionado: false,
                canModifyDiagram: true
            };
    
            setCurrentProject(newCurrentProject);
    
            const newProject = {
                conversationId: uuidv4(),
                date: "2024-07-26 14:44:26.906481+00:00",
                projectName: "NewChat!"
            };
    
            setProjects(prevProjects => [newProject, ...prevProjects]);

        } catch (error) {
            if (process.env.REACT_APP_NODE_ENV !== 'production') {
                console.error('Error loading new project: ', error);
            }
            
        }
    }, []);

    const modifyOrDeleteProjectAndSave = async (project, newName) => {
        if (newName) {
            setProjects(prevProjects => {
                const projectIndex = prevProjects.findIndex(pr => pr.conversationId === project.conversationId);
    
                if (projectIndex !== -1) {
                    const updatedProjects = [...prevProjects];
                    updatedProjects[projectIndex] = { ...updatedProjects[projectIndex], projectName: newName };
    
                    axios.post(process.env.REACT_APP_NO_STREAM_API, {
                        call: 5,
                        conversation_id: updatedProjects[projectIndex].conversationId,
                        modify: true,
                        new_name: newName
                    }).catch(error => {
                        if (process.env.REACT_APP_NODE_ENV !== 'production') {
                            console.error('Error updating project name: ', error);
                        }
                        
                    });
    
                    return updatedProjects;
                }
    
                return prevProjects;
            });
        } else {
            await loadNewProject(currentSub, id, defaultMessages, defaultModel, defaultDiagram, defaultSimBlocks);
            
            setProjects(prevProjects => {
                const projectIndex = prevProjects.findIndex(pr => pr.conversationId === project.conversationId);
    
                if (projectIndex !== -1) {
                    const updatedProjects = prevProjects.filter((_, index) => index !== projectIndex);
    
                    axios.post(process.env.REACT_APP_NO_STREAM_API, {
                        call: 5,
                        conversation_id: project.conversationId,
                        modify: false
                    }).catch(error => {
                        if (process.env.REACT_APP_NODE_ENV !== 'production') {
                            console.error('Error deleting project: ', error);
                        }
                        
                    });
    
                    return updatedProjects;
                }
    
                return prevProjects;
            });
        }
    };

    

    

    useEffect(() => {

        const initialize = async () => {

            const { sub_param, default_messages, default_diagram, default_simblocks } = await getUser()
        
            const default_model = await fetchProjects(true, sub_param, id);

            await loadNewProject(sub_param, id, default_messages, default_model, default_diagram, default_simblocks)
    
            setInitialized(true); // once api call has completed
        };

        if (
            (location.pathname === '/chat')
            && !initialized && id !== null
        ) {
            
            initialize();
        }
    
        
    }, [location.pathname, initialized, getUser, id, fetchProjects, loadNewProject]);

    
    

    // Compute selectedCount using useMemo
    const selectedCount = useMemo(() => {
        const selectedInstancesCount = currentProject.elements.reduce((count, element) => {
            return count + element.instances.filter(instance => instance.seleccionado).length;
        }, 0);

        const selectedFlowlinesCount = currentProject.flowLines.filter(flowline => flowline.seleccionado).length;

        return selectedInstancesCount + selectedFlowlinesCount;
    }, [currentProject]);

    
    const fetchProjectDetails = async (projectId) => {
        setLoading(true);
        try {
          const response = await axios.post(process.env.REACT_APP_NO_STREAM_API, {
            call: 2,
            conversation_id: projectId,
            sub: currentSub
          });
  
          const conversationHistory = response.data.conversation_history;
  
          setCurrentProject({
            elements: response.data.diagram_for_gemini.args.elements,
            flowLines: response.data.diagram_for_gemini.args.flow_lines,
            icons: response.data.diagram_for_gemini.args.icons,
            messages: conversationHistory,
            diagramRemaining: response.data.diagram_remaining,
            conversationId: projectId,
            id: id,
            key: response.data.key,
            key_d: response.data.key_d,
            key_c: response.data.key_c,
            sub: response.data.sub,
            projectName: response.data.project_name,
            lastModelUsed: response.data.last_model_used,
            seleccionado: false,
            simBlocks: response.data.sim_blocks,
            canModifyDiagram: currentProject.canModifyDiagram
          });
          setDefaultModel(response.data.last_model_used)
          
        } catch (error) {
          console.error('Error fetching project details: ', error);
        } finally {
          setLoading(false); 
        }
      };
    
    const saveProject = async (project, triggerLoader = false, cancelRun=false) => {
        if (triggerLoader) {
          setLoading(true);
        }
      
        try {
          await axios.post(process.env.REACT_APP_NO_STREAM_API, {
            call: 4,
            sub: project.sub,
            project_name: 'default project name',
            project,
            cancelRun
          });
        } catch (error) {
            if (process.env.REACT_APP_NODE_ENV !== 'production') {
                console.error('Error saving project: ', error);
            }
        } finally {
          if (triggerLoader) {
            setLoading(false);
          }
        }
    };
    
    const resetSelections = () => {
        const updatedProject = {
            ...currentProject,
            elements: currentProject.elements.map((el) => ({
                ...el,
                instances: el.instances.map((inst) => ({
                    ...inst,
                    seleccionado: false,
                })),
            })),
            flowLines: currentProject.flowLines.map((fl) => ({
                ...fl,
                seleccionado: false,
            })),
        };
        setCurrentProject(updatedProject);
    };    

    return (
        <ProjectContext.Provider value={{
            projects, 
            currentSub,
            currentProject, 
            setCurrentProject, 
            fetchProjects,
            setProjects,
            fetchProjectDetails, 
            loadNewProject, 
            saveProject, 
            addOrModifyElementinProjectAndSave,
            addOrModifyElementInstanceInProject,
            addOrModifyFlowLineInstanceInProject,
            deleteElementInstanceAndAttachedFlowLinesInProject,
            deleteFlowlineInstanceInProject,
            deleteElementInProjectAndSave,
            modifyOrDeleteProjectAndSave,
            selectedCount,
            addOrModifySimBlockInProjectAndSave,
            deleteSimBlockInProjectAndSave,
            setLoading,
            loading,
            resetSelections,
            defaultModel,
            setDefaultModel,
            availableModels,
            id,
            setId,
            defaultMessage,
            predefinedNodes,
            productSpecificationsSelections,
            setProductSpecificationsSelections
        }}>
            {children}
        </ProjectContext.Provider>
    );    
};