import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import HomePage from './Rutas/HomePage/HomePage';
import Chat from './Rutas/Chat/Chat';
import { ProjectProvider } from './context/ProjectProvider';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import PrivacyPolicy from './Rutas/PrivacyPolicy/PrivacyPolicy';
import TermsOfUse from './Rutas/TermsOfUse/TermsOfUse';
import About from './Rutas/About/About';

function App() {
  return (
    <Router>
      <ProjectProvider>
        <DndProvider backend={HTML5Backend}>
        <Routes>
  <Route path="/" element={<HomePage />} />
  <Route path="/chat" element={<Chat />} />
  <Route path="/privacy-policy" element={<PrivacyPolicy />} />
  <Route path="/terms-of-use" element={<TermsOfUse />} />
  <Route path="/about" element={<About />} />
</Routes>
        </DndProvider>
      </ProjectProvider>
    </Router>
  );
}

export default App;
