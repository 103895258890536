import React, { useEffect } from 'react';
import './HomePage.css';
import HomePageHeader from '../../componentsHomePage/HomepageHeader/HomepageHeader';
import HomepageBody from '../../componentsHomePage/HomepageBody/HomepageBody';
import HomepageFooter from '../../componentsHomePage/HomepageFooter/HomepageFooter';
import { useLocation } from 'react-router-dom';
import { scroller } from 'react-scroll';

function HomePage({ backgroundColor }) {
  const location = useLocation();

  useEffect(() => {
    if (location.state && location.state.scrollTo) {
      const sectionId = location.state.scrollTo;
      scroller.scrollTo(sectionId, {
        smooth: true,
        duration: 500,
        offset: -50,
      });
    }
  }, [location]);

  return (
    <div className="page-container" style={{ backgroundColor }}>
      <HomePageHeader />
      <HomepageBody />
      <HomepageFooter />
    </div>
  );
}

export default HomePage;
