import React, { useState, useEffect, useCallback } from 'react';
import './SimulatorFocus.css';
import { FaTimes } from 'react-icons/fa';
import { v4 as uuidv4 } from 'uuid';
import { useMyContext } from '../../../../context/ProjectProvider';
import Editor from 'react-simple-code-editor';
import { highlight, languages } from 'prismjs';
import 'prismjs/components/prism-python';
import 'prismjs/themes/prism-tomorrow.css'; // Importar el tema de Prism.js

const SimulatorFocus = ({ onClose, mode, simulator }) => {
  const [name, setName] = useState(simulator ? simulator.name : '');
  const [text, setText] = useState(simulator ? simulator.text : '');
  const [output, setInitialOutput] = useState(simulator ? simulator.output: '');
  const [initialName, setInitialName] = useState(simulator ? simulator.name : '');
  const [initialText, setInitialText] = useState(simulator ? simulator.text : '');
  const [isOpen, setIsOpen] = useState(false);
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [nameExists, setNameExists] = useState(false);
  const [copied, setCopied] = useState(false);

  const { currentProject, addOrModifySimBlockInProjectAndSave } = useMyContext();

  useEffect(() => {
    setIsOpen(true);
  }, []);

  const checkNameExists = useCallback(
    (name) => {
      const exists = currentProject?.simulators?.some(
        (sim) => sim.name.toLowerCase() === name.toLowerCase()
      );
      setNameExists(exists);
    },
    [currentProject?.simulators]
  );

  useEffect(() => {
    if (mode === 'create') {
      checkNameExists(name);
    }
  }, [name, mode, checkNameExists]);

  const handleCreateOrModifySimulator = () => {
    if (nameExists && mode === 'create') return;

    const newSimulatorBlock = {
      id: simulator ? simulator.id : uuidv4(),
      name,
      text,
      output,
    };
    addOrModifySimBlockInProjectAndSave(newSimulatorBlock);
    setInitialName(name); // Reset initial values
    setInitialText(text);
    setInitialOutput(output);
    handleClose(false); // Close without checking for unsaved changes
  };

  const handleClose = (checkChanges = true) => {
    if (
      checkChanges &&
      (name !== initialName || text !== initialText) &&
      (mode === 'create' || mode === 'modify')
    ) {
      setShowConfirmationPopup(true);
    } else {
      setIsOpen(false);
      setTimeout(onClose, 300);
    }
  };

  const handleConfirmClose = () => {
    setIsOpen(false);
    setTimeout(onClose, 300);
  };

  const isSaveDisabled = name === initialName && text === initialText;
  const isCreateDisabled = name.trim() === '' || text.trim() === '' || nameExists;

  // Función para copiar el contenido de "npm__react-simple-code-editor__textarea"
  const handleCopyCode = () => {
    const editorTextarea = document.querySelector('.npm__react-simple-code-editor__textarea');
    if (editorTextarea) {
      navigator.clipboard.writeText(editorTextarea.value).then(
        () => {
          setCopied(true); // Cambia el estado a `true` cuando se copia
          setTimeout(() => setCopied(false), 5000); // Vuelve a `false` después de 5 segundos
        },
        (err) => console.error('Error al copiar el texto: ', err)
      );
    }
  };
  

  return (
    <div
      className={`simulator-focus-overlay ${isOpen ? 'open' : ''}`}
      onClick={handleClose}
    >
      <div
        className={`simulator-focus ${isOpen ? 'open' : ''} ${
          !isOpen ? 'closing' : ''
        }`}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="simulator-focus-header">
          <div className="simulator-focus-header-left">
            <button onClick={() => handleClose(true)} className="close-button">
              <FaTimes />
            </button>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Simulator name"
              maxLength="50"
              style={{ color: 'white' }}
            />
          </div>
          <button
            onClick={handleCreateOrModifySimulator}
            className="save-button"
            disabled={mode === 'create' ? isCreateDisabled : isSaveDisabled}
            data-tooltip={
              nameExists
                ? 'Existing simulator name'
                : mode === 'create'
                ? 'Please enter a name and description'
                : 'No changes made'
            }
          >
            {mode === 'create' ? (
              <i className="bi bi-save"></i>
            ) : (
              <i className="bi bi-floppy2"></i>
            )}
          </button>
        </div>
        <div className="simulator-focus-body">
        <div className="simulator-focus-title-container">
          <h3 className="simulator-focus-title">Python Code for Simulator</h3>
          <button
            className="copy-code-button"
            onClick={handleCopyCode}
            title="Copiar código"
          >
            {copied ? <i className="bi bi-check2"></i> : <i className="bi bi-copy"></i>}
          </button>
        </div>
          <Editor
            value={text}
            onValueChange={(code) => setText(code)}
            highlight={(code) => highlight(code, languages.python, 'python')}
            padding={10}
            className="code-editor"
            style={{
              fontFamily: '"Fira code", "Fira Mono", monospace',
              fontSize: 14,
              color: 'white',
              backgroundColor: '#2d2d2d',
              border: '1px solid #4f4f4f',
              borderRadius: '5px',
              minHeight: '200px',
              overflowY: 'auto'
            }}
          />
          <span className="char-count">{5000 - text.length} characters remaining</span>
        </div>
      </div>
      {showConfirmationPopup && (
        <div className="popup" onClick={() => setShowConfirmationPopup(false)}>
          <div className="popup-content" onClick={(e) => e.stopPropagation()}>
            <div className="popup-line">
              {mode === 'create'
                ? "Are you sure you don't want to create the simulator?"
                : "Are you sure you don't want to save the simulator?"}
              <button
                className="close-button"
                onClick={() => setShowConfirmationPopup(false)}
              >
                <FaTimes />
              </button>
            </div>
            <div className="popup-buttons">
              <button
                className="cancel-button"
                onClick={() => setShowConfirmationPopup(false)}
              >
                Cancel
              </button>
              <button className="delete-button" onClick={handleConfirmClose}>
                {mode === 'create'
                  ? 'Close without creating'
                  : 'Close without saving'}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SimulatorFocus;
