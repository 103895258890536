import React, { useState, useEffect, useRef, useCallback } from 'react';
import './FocusFlowline.css';

const FocusFlowline = ({ flowline, onClose, onSave, clickPosition }) => {
  const [newName, setNewName] = useState(flowline.name);
  const modalRef = useRef(null);

  const handleSave = () => {
    onSave(newName);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSave();
    }
  };

  const handleClickOutside = useCallback(
    (e) => {
      if (modalRef.current && !modalRef.current.contains(e.target)) {
        onClose();
      }
    },
    [onClose]
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);

  const calculatePopupPosition = () => {
    const popupWidth = 270; // Ancho fijo del popup
    const popupHeight = modalRef.current ? modalRef.current.offsetHeight : 0;
    const additionalPadding = 10; // Espacio adicional
    let top = clickPosition.y - popupHeight - additionalPadding;
    let left = clickPosition.x - popupWidth - additionalPadding;

    // Si el popup se sale por la izquierda, colocarlo a la derecha del clickPosition
    if (left < 0) {
      left = clickPosition.x + additionalPadding;
    }

    // Si el popup se sale por arriba, colocarlo debajo del clickPosition
    if (top < 0) {
      top = clickPosition.y + additionalPadding;
    }

    // Ajustar si el popup se sale por la derecha
    if (left + popupWidth > window.innerWidth) {
      left = window.innerWidth - popupWidth - additionalPadding;
    }

    // Ajustar si el popup se sale por abajo
    if (top + popupHeight > window.innerHeight) {
      top = window.innerHeight - popupHeight - additionalPadding;
    }

    return { top, left };
  };

  const popupStyle = {
    position: 'fixed', // Usamos position fixed para que el popup se posicione relativo a la ventana
    width: '270px',
    top: `${calculatePopupPosition().top}px`,
    left: `${calculatePopupPosition().left}px`,
    zIndex: 1002,
  };

  return (
    <>
      <div className="overlay" onClick={onClose}></div> {/* Fondo oscuro */}
      <div className="focus-flowline-wrapper">
        <div className="overlay" onClick={onClose}></div> {/* Fondo oscuro */}
        <div className="focus-flowline" style={popupStyle} ref={modalRef}>
          <div className="focus-flowline-content">
            <div className="group-focus-header">
              <i className="bi bi-x" onClick={onClose} title="Close"></i>
              <i className="bi bi-box-arrow-down" onClick={handleSave} title="Save"></i>
            </div>
            <div className="focus-flowline-body">
              <label className="flowline-label">Name/Characteristics of the Flowline</label>
              <textarea
                value={newName}
                onChange={(e) => setNewName(e.target.value)}
                onKeyDown={handleKeyDown}
                placeholder="Edit flowline name"
                className="flowline-name-input"
                rows={1}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FocusFlowline;
