import React, { useEffect } from 'react';
import HomepageHeader from '../../componentsHomePage/HomepageHeader/HomepageHeader';
import HomepageFooter from '../../componentsHomePage/HomepageFooter/HomepageFooter';
import './PrivacyPolicy.css';

function PrivacyPolicy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <HomepageHeader />
        <div className="privacy-policy-content">
        <h1>Privacy Policy of DeepLayered</h1>
        <p>Last Updated: October 28, 2024</p>
        <ol>
            <li>
            <strong>Introduction.</strong> This Privacy Policy discloses the policies of DeepLayered at{' '}
            <a href="https://deeplayered.ai/">https://deeplayered.ai/</a> and its operator DeepLayered, an Ohio LLC located at 51 Falcon Circle, Amelia, Ohio 45102 (collectively “we” or “us”) regarding the collection, use, and disclosure of information that you (a “User”) submit to us or that is collected through your use of DeepLayered.
            </li>
            <li>
            <strong>Location.</strong> DeepLayered is located in the United States and is subject to United States laws. If you are outside of the United States, your use of the website constitutes consent to the transmission of personal information to the United States. If your use of DeepLayered is against the laws of your jurisdiction, it is your responsibility to refrain from the use of DeepLayered.
            </li>
            <li>
            <strong>Types of Personal Information Collected.</strong> “Personal information” is data that identifies, relates to, describes, can be used to contact, or could reasonably be linked directly or indirectly to a User. For purposes of this Policy, there is no meaningful distinction between the terms “personal information” and “personal data.”
            <ul>
                <li>
                <strong>a. Personal Information Collected - General.</strong> We collect the following items of personal information from Users:
                <ul>
                    <li>Name</li>
                    <li>Lastname</li>
                    <li>Email</li>
                    <li>Phone number</li>
                    <li>IP address</li>
                </ul>
                </li>
                <li>
                <strong>b. Sensitive Personal Information.</strong> We do not proactively collect sensitive personal information as defined under Article 9 of the GDPR.
                </li>
            </ul>
            </li>
            <li>
            <strong>Collection of Personal Information.</strong>
            <ul>
                <li>
                <strong>a. Submission from User.</strong> Certain items of personal information we rely on you to submit to us. A user submits information (first name, last name, email, and phone number) when they send a contact form. A user submits information (first name, last name, and email) when creating an account on DeepLayered. If a user uses the free application without creating an account, their IP address is collected.
                </li>
            </ul>
            </li>
            <li>
            <strong>Cookies and Other Tracking Technologies.</strong>
            <ul>
                <li>
                <strong>a. Third Party Cookies.</strong> We work with third parties that place cookies on our Website to provide:
                <ul>
                    <li>
                    <strong>i. Data Providers.</strong> We use third-party data provider cookies in connection with DeepLayered. The following third parties utilize data provider cookies on DeepLayered: Google Analytics. They are used to improve the user experience.
                    </li>
                    <li>
                    <strong>ii. Analytics.</strong> We use third-party analytics cookies to gain insight into how our visitors use DeepLayered. The following third parties utilize cookies on DeepLayered for analytics: Google. Enhance the user experience.
                    </li>
                </ul>
                </li>
                <li>
                <strong>b. Third Party Cookies Control.</strong> To the extent DeepLayered exercises any control over the cookies created by third parties, it will be done in compliance with this Privacy Policy. Other companies' use of their cookies is subject to their own privacy policies and not ours.
                </li>
                <li>
                <strong>c. Opting Out of Cookies and Other Tracking Technologies.</strong>
                <ul>
                    <li>
                    <strong>i. Opting out of Third Party Cookies.</strong> To opt out of third-party cookies, refer to the methods they have posted on their individual websites.
                    </li>
                </ul>
                </li>
            </ul>
            </li>
            <li>
            <strong>Processing Bases.</strong> Pursuant to Art. 6 of the GDPR, we rely on the following legal bases to process your personal information:
            <ul>
                <li>
                <strong>a. User Consent:</strong> When you give permission to process your personal information.
                </li>
                <li>
                <strong>b. Performance of Contract.</strong> We process your personal information to adequately perform the contract that we have with you, such as retaining your information to effectively receive and forward mail.
                </li>
            </ul>
            </li>
            <li>
            <strong>Use of Personal Information.</strong> In general, we use your personal information to:
            <ul>
                <li>Communicate with you regarding your order, your account, the use of our products or services, or otherwise provide customer service;</li>
                <li>Maintain your account and keep track of any purchases made by you;</li>
                <li>Authenticate your account credentials and identify you to log in to the Website;</li>
                <li>Personalize your experience on the Website;</li>
                <li>Send you marketing communications about services and products offered by DeepLayered and its affiliate companies, as permitted by law;</li>
                <li>Improve our website; your personal information may be analyzed to search for patterns which may help improve the overall website experience;</li>
                <li>Improve the customer service we provide; your personal information may be used for review and quality control purposes;</li>
                <li>Create de-identified personal information to be used by us; and</li>
                <li>Use for any other reason you consent to.</li>
            </ul>
            </li>
            <li>
            <strong>Electronic Communications.</strong> Consistent with this Privacy Policy and as is permitted by applicable law, we may communicate with you via electronic messages, including email. We receive notice when you open an email or click a link within a marketing email.
            </li>
            <li>
            <strong>Disclosure of Personal Information to Third Parties.</strong> We disclose the personal information we collect (or otherwise generate or obtain) for the following purposes:
            <ul>
                <li>
                <strong>a. Our Affiliates.</strong> We disclose your personal information to affiliates of DeepLayered for the following purpose: Improve artificial intelligence services.
                </li>
                <li>
                <strong>b. Sale of Personal Information.</strong> We sell your personal information subject to your right to opt-out of such sales.
                </li>
                <li>
                <strong>c. In the event of a corporate transaction.</strong> We may disclose or transfer your information to a third party if we sell, transfer, divest, or disclose all or a portion of our business or assets to another company in connection with or during negotiation of any merger, financing, acquisition, bankruptcy, dissolution, transaction, or proceeding.
                </li>
                <li>
                <strong>d. Use to protect against, investigate, and deter fraudulent activity.</strong> When necessary, your personal information may be disclosed to third parties for the purpose of protecting against, investigating, and deterring fraudulent, as well as unauthorized or illegal, activity.
                </li>
                <li>
                <strong>e. Defend our legal rights.</strong> To protect and defend the legal rights of DeepLayered and its employees, we may disclose your personal information. We may also require your personal information to defend against legal claims or to establish our legal rights.
                </li>
                <li>
                <strong>f. To comply with and as required by law.</strong> We may disclose your personal information to government authorities or other relevant third parties in order to comply with applicable legal requirements, judicial proceedings, court orders, subpoenas, legal processes, or lawful requests from governmental authorities.
                </li>
                <li>
                <strong>g. At your request.</strong> We also share your information as directed or requested by you, or subject to your consent. In some circumstances, you may have the right to opt-out or object to our sharing of your information with certain third parties.
                </li>
            </ul>
            </li>
            <li>
            <strong>The Ability of Others to View Your Information.</strong> When you use the Website, certain information you post or provide on the publicly available portions of the Website may become publicly available and may be collected and used by others, including people outside of the control of the Website. We have no obligations with respect to any information that you post to publicly available parts of the Website.
            </li>
            <li>
            <strong>Cross Border Transfer Safeguards.</strong> We use contracts based on standard clauses approved by the European Union and work exclusively with partners and providers who comply with strict security and privacy standards. Our goal is to ensure that user information is protected under the same security criteria in any jurisdiction where we operate, preserving the confidentiality, integrity, and accessibility of their data.
            </li>
            <li>
            <strong>Retention of Personal Information.</strong> Unless there is something in this Policy to the contrary, we may retain your personal information for as long as your account is active and for a period of 10 years thereafter to allow you to re-activate your account without loss of information. We may also retain your personal information as necessary to comply with legal requirements and obligations, defend our rights, resolve conflicts, improve our website, address complaints, and enforce contracts.
            </li>
            <li>
            <strong>Storage of Credit Card Information.</strong> We do not store your credit card or debit card information ourselves. We do not access such information for other purposes without your permission. While credit or debit card information is safeguarded through our third-party service provider, no method of electronic storage is 100% secure and we cannot guarantee its absolute security. When your credit or debit card account information is being transmitted through our Website, it will be protected by security protocols.
            </li>
            <li>
            <strong>Third Party Links.</strong> DeepLayered may contain links to other sites that are not owned or controlled by DeepLayered. We are not responsible for the privacy practices or the content of such other websites, and this Privacy Policy does not apply to any other sites. We make no representation regarding the privacy practices of any other sites regardless of whether we advertise on those sites or provide a link to those sites from our Website.
            </li>
            <li>
            <strong>California Privacy Rights Act Disclosure.</strong>
            <ul>
                <li>
                <strong>a. Personal Information Collected.</strong> In the past 12 months, DeepLayered has collected the following categories of information from California residents:
                <ul>
                    <li>[List of categories of personal information collected]</li>
                </ul>
                </li>
                <li>
                <strong>b. Sources of Information.</strong> Personal Information about California residents is collected from the following sources:
                <ul>
                    <li>Directly from you</li>
                    <li>Indirectly from you, such as by observing your online browsing and usage activity</li>
                    <li>From publicly available sources</li>
                </ul>
                </li>
                <li>
                <strong>c. Business Purpose for Collecting Personal Information.</strong> Personal Information about California residents is collected for the following business and commercial purposes:
                <ul>
                    <li>Auditing related to counting ad impressions to unique visitors, verifying positioning and quality of ad impressions, and auditing compliance with this specification and other standards.</li>
                    <li>Helping to ensure security and integrity to the extent the use of the consumer’s personal information is reasonably necessary and proportionate for these purposes.</li>
                    <li>Debugging to identify and repair errors that impair existing intended functionality.</li>
                    <li>Short-term, transient use, including, but not limited to, non-personalized advertising shown as part of a consumer’s current interaction with the business.</li>
                    <li>Performing services on behalf of the business, including maintaining or servicing accounts, providing customer service, processing or fulfilling orders and transactions, verifying customer information, processing payments, providing financing, providing analytic services, providing storage, or providing similar services on behalf of the business.</li>
                    <li>Providing advertising and marketing services, except for cross-context behavioral advertising, to the consumer.</li>
                    <li>Undertaking internal research for technological development and demonstration.</li>
                    <li>Undertaking activities to verify or maintain the quality or safety of a service or device that is owned, manufactured, or controlled by the business.</li>
                    <li>Other commercial purposes as indicated in the section titled "Use of Personal Information."</li>
                </ul>
                </li>
                <li>
                <strong>d. Personal Information Disclosed.</strong> In the past 12 months, DeepLayered has disclosed the following categories of personal information belonging to California residents:
                <ul>
                    <li>[List of categories of personal information disclosed]</li>
                </ul>
                </li>
                <li>
                <strong>e. Categories of Third Parties Receiving Personal Information.</strong> We disclose your personal information to the following categories of third parties:
                <ul>
                    <li>Service Providers</li>
                    <li>Affiliates</li>
                </ul>
                </li>
                <li>
                <strong>f. No Sale or Sharing of Personal Information.</strong> In the past 12 months, DeepLayered has not sold or shared the personal information of California residents.
                </li>
            </ul>
            </li>
            <li>
            <strong>Your Choices and Rights</strong>
            <ul>
                <li>
                <strong>a. General.</strong> You may choose not to provide some of the personal information described above. Please note, however, that many of our services require some personal information to operate, so if you choose not to provide the personal information necessary to operate and provide you with a particular service or feature, you may not be able to use that service or feature.
                </li>
                <li>
                <strong>b. Rights Under the GDPR.</strong>
                <ul>
                    <li>
                    <strong>i. Generally.</strong> Users that reside in the European Union, Iceland, Lichtenstein, Norway, Switzerland, or the United Kingdom have the following rights:
                    <ul>
                        <li>The Right to Access</li>
                        <li>The Right to Rectification</li>
                        <li>The Right to Erasure</li>
                        <li>The Right to Restrict Processing</li>
                        <li>The Right to Object to Processing</li>
                        <li>The Right to Data Portability</li>
                    </ul>
                    </li>
                    <li>
                    <strong>ii. Exercising GDPR Rights.</strong> If you reside in one of the countries above and wish to exercise your rights, you may contact us by one of the following methods:
                    <ul>
                        <li>Sending a request to <a href="mailto:contact@deeplayered.ai">contact@deeplayered.ai</a></li>
                    </ul>
                    </li>
                    <li>
                    <strong>iii. Time to Respond.</strong> DeepLayered has 30 days to respond to your request.
                    </li>
                    <li>
                    <strong>iv. Supervisory Authority.</strong> Should you wish to report a complaint or if you feel that we have not addressed your concern in a satisfactory manner, you may contact your local data protection supervisory authority.
                    </li>
                </ul>
                </li>
                <li>
                <strong>c. Rights Under the CPRA.</strong>
                <ul>
                    <li>
                    <strong>i. Generally.</strong> Residents of California have the following rights under the California Privacy Rights Act of 2020 (CPRA):
                    <ul>
                        <li>The right to request that we disclose to you the categories of personal information we have collected about you, specific pieces of personal information collected about you, the sources of that personal information, the commercial purpose of collecting your personal information, and the categories of third parties that personal information is disclosed to.</li>
                        <li>If we sold your personal information, you have the right to request that we disclose your categories of information sold and disclosed.</li>
                        <li>The right to request that we delete any personal information about you which we have collected from you.</li>
                        <li>The right to request that we correct any inaccurate personal information we have about you.</li>
                        <li>The right not to be discriminated against for exercising your consumer rights.</li>
                    </ul>
                    </li>
                    <li>
                    <strong>ii. Exercising CPRA Rights.</strong> If you live in California and want to exercise your rights, you may contact us by one of the following methods:
                    <ul>
                        <li>Sending a request to <a href="mailto:contact@deeplayered.ai">contact@deeplayered.ai</a></li>
                    </ul>
                    </li>
                </ul>
                </li>
                <li>
                <strong>d. Consent.</strong> For any personal information that is collected, used, or processed pursuant to your consent, you may withdraw your consent at any time by contacting us by one of the methods listed below. Withdrawal of your consent will not affect the use or collection of your personal information prior to your withdrawal.
                </li>
                <li>
                <strong>e. Ability to Unsubscribe From Marketing Emails.</strong> You may “opt-out” of electronic communications from us by using the "unsubscribe" link or any method described in the electronic communication. You may also contact us through the methods described below to unsubscribe from email communications. This applies to promotional communications and not administrative communications we feel are necessary to provide the services on the Website.
                </li>
                <li>
                <strong>f. Sharing with Third Parties.</strong> We do not automatically share your personal information with third-party service providers without your consent, except for any such disclosures that are necessary to fulfill the services you contracted for with us to provide through the Website. All other disclosures to third-party business service providers are only made through your affirmative actions, by indicating on the Website that you are interested in third-party services. To prevent the disclosure of your personal information to these third-party service providers, do not opt-in for the service.
                </li>
            </ul>
            </li>
            <li>
            <strong>Corrections to Personal Information.</strong> If you have created an online account with us and would like to update the information you have provided to us, please access your account to view and update your information.
            </li>
            <li>
            <strong>Do-Not-Track Requests.</strong> “Do-Not-Track” is a web browser setting that sends a signal instructing the website not to track a user. DeepLayered currently does not have a mechanism to respond to “Do-Not-Track” requests.
            </li>
            <li>
            <strong>Revisions.</strong> This Privacy Policy may be revised from time to time. We will post any such changes to this page and may otherwise post other alerts on the Website or communicate such changes through email. If you disagree with any such changes, please cease using the Website. Continued use following the posting of any such changes indicates your acceptance of the changes. If we make any changes to this Privacy Policy that materially impact previously collected personal information about you, we will make reasonable efforts to provide notice and obtain consent to any such changes as may be required by law.
            </li>
            <li>
            <strong>Minors.</strong> Minors under the age of 13 may not use the Website. We do not knowingly collect information from children under 13 years of age. Additionally, we recommend that users between the ages of 13 and 18 ask their parents for permission before sending any information about themselves to anyone over the Internet.
            </li>
            <li>
            <strong>Data Protection Officer.</strong> The Data Protection Officer for the website is Leonardo Tonazzolli, who may be reached by phone at 353-443-4053, by email at <a href="mailto:leonardo.tonazzolli@deeplayered.ai">leonardo.tonazzolli@deeplayered.ai</a>, or mail at Via IV Novembre 50, Trento, 38121.
            </li>
            <li>
            <strong>Terms of Use.</strong> This Privacy Policy is subject to the terms contained in DeepLayered’s Terms of Use.
            </li>
            <li>
            <strong>Contact.</strong> If you have any questions or concerns about this Policy or the way in which your personal information is handled, please contact us by email at <a href="mailto:contact@deeplayered.ai">contact@deeplayered.ai</a>.
            </li>
        </ol>
        </div>
        <HomepageFooter />
    </div>
  );
}

export default PrivacyPolicy;
