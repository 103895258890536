import React, { useState, useEffect } from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css';
import axios from 'axios';
import './SimuList.css';
import { FaEdit, FaTimes } from 'react-icons/fa';
import { useMyContext } from '../../../context/ProjectProvider';
import SimulatorFocus from '../SimulatorHeader/SimulatorFocus/SimulatorFocus';

const SimuList = ({ isSimulatorGroupCollapsed }) => {
  const {
    currentProject,
    setLoading,
    addOrModifySimBlockInProjectAndSave,
    deleteSimBlockInProjectAndSave,
  } = useMyContext();

  const [editingSimulator, setEditingSimulator] = useState(null);
  const [simulatorToDelete, setSimulatorToDelete] = useState(null);
  const [minimizedSimulators, setMinimizedSimulators] = useState({});
  const [showPopup, setShowPopup] = useState(false);
  const [copied, setCopied] = useState(false);

  // Efecto para forzar minimizado cuando SimulatorGroup está colapsado
  useEffect(() => {
    if (isSimulatorGroupCollapsed) {
      // Forzar que todos los simuladores estén minimizados
      setMinimizedSimulators((prevState) => {
        const newState = { ...prevState };
        currentProject.simBlocks.forEach((simulator) => {
          newState[simulator.id] = true;
        });
        return newState;
      });
    }
    // No hacemos nada cuando se expande; el estado se mantiene
  }, [isSimulatorGroupCollapsed, currentProject.simBlocks]);

  const handleEditClick = (simulator) => {
    setEditingSimulator(simulator);
  };

  const handleCloseSimulatorFocus = () => {
    setEditingSimulator(null);
  };

  const handleDeleteClick = (simulator) => {
    setSimulatorToDelete(simulator);
    setShowPopup(true);
  };

  const handleConfirmDelete = async () => {
    if (simulatorToDelete) {
      try {
        setLoading(true);
        await deleteSimBlockInProjectAndSave(simulatorToDelete.id);
        setMinimizedSimulators((prev) => {
          const newState = { ...prev };
          delete newState[simulatorToDelete.id];
          return newState;
        });
        setShowPopup(false);
        setSimulatorToDelete(null);
      } catch (error) {
        if (process.env.REACT_APP_NODE_ENV !== 'production') {
          console.error('Error deleting simulator:', error);
        }
      } finally {
        setLoading(false);
      }
    }
  };

  const handleCancelDelete = () => {
    setShowPopup(false);
    setSimulatorToDelete(null);
  };

  const handleMinimizeClick = (simulatorId) => {
    setMinimizedSimulators((prev) => ({
      ...prev,
      [simulatorId]: !prev[simulatorId],
    }));
  };

  const handleRunClick = async (simulator) => {
    try {
      const code_to_run = simulator.text;
      const sub = currentProject.sub;

      setLoading(true);

      const response = await axios.post(process.env.REACT_APP_NO_STREAM_API, {
        call: 6,
        sub: sub,
        code_to_run: code_to_run,
      });

      let updatedSimulator = { ...simulator };

      if (response.data.output) {
        updatedSimulator.output = response.data.output;
      }

      if (response.data.files && response.data.files.length > 0) {
        const file = response.data.files[0];
        const imageContent = `data:image/png;base64,${file}`;
        updatedSimulator.image = imageContent;
      }

      if (response.data.errors) {
        updatedSimulator.output = response.data.errors;
      }

      await addOrModifySimBlockInProjectAndSave(updatedSimulator);
    } catch (error) {
      if (process.env.REACT_APP_NODE_ENV !== 'production') {
        console.error('Error running code:', error);
      }
      
    } finally {
      setLoading(false);
    }
  };

  // Función para copiar texto al portapapeles
  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(
      () => {
        setCopied(true); // Cambia el estado a `true` cuando se copia
        setTimeout(() => setCopied(false), 5000); // Vuelve a `false` después de 5 segundos
      },
      (err) => {
        console.error('Error al copiar el texto: ', err);
      }
    );
  };  

    // Función para descargar el texto como archivo .txt
  const downloadTextAsFile = (text, fileName = 'output.txt') => {
    const blob = new Blob([text], { type: 'text/plain' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // Función para descargar la imagen
  const downloadImage = (imageData, fileName = 'image.png') => {
    const link = document.createElement('a');
    link.href = imageData;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  if (!currentProject) {
    return <div>Loading...</div>;
  }

  if (!currentProject.simBlocks || currentProject.simBlocks.length === 0) {
    return <div>No simulators available.</div>;
  }

  return (
    <div className="simu-list">
      {currentProject.simBlocks.map((simulator) => (
        <div
          key={simulator.id}
          className={`simulator-item ${
            isSimulatorGroupCollapsed ? 'collapsed' : ''
          }`}
        >
          <div className="simulator-item-header">
            <span className="simulator-name">{simulator.name}</span>

            {/* Renderizar botones solo si el grupo no está colapsado */}
            {!isSimulatorGroupCollapsed && (
              <div className="simulator-buttons">
                <button
                  className="edit-button"
                  onClick={() => handleEditClick(simulator)}
                  title="Edit simulator"
                >
                  <FaEdit />
                </button>
                <span className="separator">|</span>
                <button
                  className="delete-button"
                  onClick={() => handleDeleteClick(simulator)}
                  title="Delete simulator"
                >
                  <i className="bi bi-trash"></i>
                </button>
                <span className="separator">|</span>
                <button
                  className="minimize-button"
                  onClick={() => handleMinimizeClick(simulator.id)}
                  title={
                    minimizedSimulators[simulator.id]
                      ? 'Maximize simulator'
                      : 'Minimize simulator'
                  }
                >
                  {minimizedSimulators[simulator.id] ? (
                    <i className="bi bi-fullscreen"></i>
                  ) : (
                    <i className="bi bi-fullscreen-exit"></i>
                  )}
                </button>
              </div>
            )}
          </div>
          {!minimizedSimulators[simulator.id] &&
            !isSimulatorGroupCollapsed && (
              <div className="simulator-item-body">
                <button
                  className="run-button"
                  onClick={() => handleRunClick(simulator)}
                >
                  Run
                </button>
                <div className="simulator-group">
                  {/* Imagen generada */}
                  {simulator.image && (
                    <div className="simulator-image-container">
                      <div className="simulator-image-header">
                        <span className="simulator-image-label">
                          Generated image
                        </span>
                        <button
                          className="download-button"
                          onClick={() => downloadImage(simulator.image)}
                          title="Download image"
                        >
                          <i className="bi bi-download"></i>
                        </button>
                      </div>
                      <img
                        src={simulator.image}
                        alt="Simulator Output"
                        className="simulator-image"
                      />
                    </div>
                  )}
                  {/* Texto generado */}
                  {simulator.output && (
                    <div className="simulator-output-container">
                      <div className="simulator-output-header">
                        <span className="simulator-output-label">Generated text</span>
                        <div className="simulator-buttons-container">
                          <button
                            className="copy-button"
                            onClick={() => copyToClipboard(simulator.output)}
                            title="Copy text"
                          >
                            {copied ? <i className="bi bi-check2"></i> : <i className="bi bi-copy"></i>}
                          </button>
                          <button
                            className="download-text-button"
                            onClick={() => downloadTextAsFile(simulator.output)}
                            title="Download as .txt"
                          >
                            <i className="bi bi-download"></i>
                          </button>
                        </div>
                      </div>
                      <textarea
                        className="simulator-output"
                        value={simulator.output}
                        readOnly
                      />
                    </div>
                  )}
                </div>
              </div>
            )}
        </div>
      ))}
      {editingSimulator && (
        <SimulatorFocus
          onClose={handleCloseSimulatorFocus}
          mode="modify"
          simulator={editingSimulator}
        />
      )}
      {showPopup && (
        <div className="popup">
          <div className="popup-content">
            <div className="popup-line">
              Delete the simulator '{simulatorToDelete.name}'?
              <button className="close-button" onClick={handleCancelDelete}>
                <FaTimes />
              </button>
            </div>
            <div className="popup-buttons">
              <button className="cancel-button" onClick={handleCancelDelete}>
                Cancel
              </button>
              <button
                className="delete-button"
                onClick={handleConfirmDelete}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SimuList;
