// Models.jsx
import React, { useState } from 'react';
import { useMyContext } from '../../context/ProjectProvider';
import './Models.css';

const Models = ({ onSelectModel, onToggleChatBar, isChatBarExpanded }) => {
    const [isDropdownOpen, setDropdownOpen] = useState(false);

    const { currentProject, availableModels } = useMyContext();

    const handleModelClick = (model) => {
        onSelectModel(model);
        setDropdownOpen(false);
    };

    return (
        <div className="models-container">
            <button className="models-button" onClick={() => setDropdownOpen(!isDropdownOpen)}>
                {currentProject.lastModelUsed} <i className="bi bi-caret-down-fill"></i>
            </button>
            <button className="expand-button" onClick={onToggleChatBar}>
                <i className={isChatBarExpanded ? "bi bi-align-middle" : "bi bi-align-top"}></i>
            </button>
            {isDropdownOpen && (
                <div className="models-dropdown">
                    <div className="models-dropdown-title">Models</div>
                    {availableModels.map((model) => (
                        <div key={model} className="models-dropdown-item" onClick={() => handleModelClick(model)}>
                            {model}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default Models;
