import React, { useState } from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './SimulatorHeader.css';
import SimulatorFocus from './SimulatorFocus/SimulatorFocus';
import { useMyContext } from '../../../context/ProjectProvider'; // Importa el hook de contexto

const SimulatorHeader = ({ isCollapsed, toggleCollapse }) => {
  const [isSimulatorFocusOpen, setIsSimulatorFocusOpen] = useState(false);
  const { resetSelections } = useMyContext(); // Usa el contexto para obtener resetSelections

  const handleNewSimulatorClick = () => {
    resetSelections(); // Llamada a resetSelections desde el contexto
    setIsSimulatorFocusOpen(true);
  };

  return (
    <div className="simulator-header">
      <div className="left-buttons">
        <button
          className="collapse-button"
          onClick={toggleCollapse}
          title={isCollapsed ? "Show simulators" : "Hide simulator"}
          id="673"
        >
          {isCollapsed ? (
            <i className="bi bi-arrow-bar-left"></i>
          ) : (
            <i className="bi bi-arrow-bar-right"></i>
          )}
        </button>
      </div>
      {!isCollapsed && (
        <div className="right-buttons">
          {/*<button
            className="build-simulator-button"
            title="Build AI simulators based on their diagrams"
          >
            <i className="bi bi-bezier2"></i>
          </button>*/}
          <button
            className="new-simulator-button"
            onClick={handleNewSimulatorClick}
          >
            New simulator
          </button>
        </div>
      )}
      {isSimulatorFocusOpen && (
        <SimulatorFocus
          onClose={() => setIsSimulatorFocusOpen(false)}
          mode="create"
        />
      )}
    </div>
  );
};

export default SimulatorHeader;
