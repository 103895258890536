import React, { useState, useRef } from 'react';
import { useDrag } from 'react-dnd';
import ReactDOM from 'react-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import './ElementItem.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
//import { predefinedNodes } from '../GroupFocus/nodes'; // Ajusta la ruta según sea necesario
import { useMyContext } from '../../context/ProjectProvider';

const PortalDropdownMenu = ({ children }) => {
  return ReactDOM.createPortal(
    children,
    document.body
  );
};

const ElementThumbnail = ({ node, lineWidth = 1, nodeSize = 2 }) => {
  if (!node || !node.nodes) {
    return null;
  }

  const shape = node;

  return (
    <svg
      className="thumbnail-svg"
      preserveAspectRatio="xMidYMid meet"
      viewBox="-5 -5 80 110"
    >
      {shape.edges &&
        shape.edges.map((edge, index) => {
          const startNode = shape.nodes.find((n) => n.id === edge.beginning);
          const endNode = shape.nodes.find((n) => n.id === edge.end);
          if (!startNode || !endNode) return null;
          return (
            <line
              key={`edge-${index}`}
              x1={startNode.x}
              y1={startNode.y}
              x2={endNode.x}
              y2={endNode.y}
              className="thumbnail-edge"
              strokeWidth={lineWidth}
            />
          );
        })}
      {shape.nodes &&
        shape.nodes.map((node) => (
          <circle
            key={`node-${node.id}`}
            cx={node.x}
            cy={node.y}
            r={nodeSize}
            className="thumbnail-node"
          />
        ))}
    </svg>
  );
};



const ElementItem = ({ element, onModify, onDelete, onClick }) => {
  const { resetSelections, predefinedNodes } = useMyContext();
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const dropdownRef = useRef(null);


  const getNodeByKey = (key) => {
  
    let node = null;
    if (predefinedNodes.independent?.nodes[key]) {
      node = predefinedNodes.independent.nodes[key];
    } else {
      for (const folderKey in predefinedNodes) {
        if (folderKey !== 'independent' && predefinedNodes[folderKey].nodes[key]) {
          node = predefinedNodes[folderKey].nodes[key];
          break;
        }
      }
    }
    return node;
  };

  const [{ isDragging }, drag] = useDrag(() => ({
      type: 'element',
      item: {
          ...element,
          type: 'rect',
          width: 50,
          height: 50,
          x: 0,
          y: 0
      },
      collect: (monitor) => ({
          isDragging: !!monitor.isDragging()
      }),
  }));

  const handleToggleDropdown = () => {
      setDropdownVisible(!dropdownVisible);
  };

  const handleMouseLeave = (e) => {
    if (dropdownRef.current && e.relatedTarget instanceof Node && !dropdownRef.current.contains(e.relatedTarget)) {
      setDropdownVisible(false);
    }
  };

  const isInstanceSelected = (element) => {
      return element.instances.some(inst => inst.seleccionado);
  };

  const handleModifyClick = () => {
    resetSelections(); // Llama a resetSelections primero
    setTimeout(() => onModify(element), 0); // Espera un instante antes de abrir el elemento a modificar
};

  return (
      <div
          ref={drag}
          className={`element-item d-flex justify-content-between align-items-center ${isInstanceSelected(element)? 'selected' : ''} ${dropdownVisible ? 'hovered' : ''}`}
          style={{ opacity: isDragging ? 0.5 : 1 }}
          onMouseLeave={handleMouseLeave}
          onClick={onClick}
      >
          <div className="element-icon">
              <ElementThumbnail node={getNodeByKey(element.icon)} lineWidth={1.5} nodeSize={2} />
          </div>
          <span className="element-text">{element.name}</span>
          <Dropdown className="element-item-dots" show={dropdownVisible} onToggle={handleToggleDropdown}>
              <Dropdown.Toggle as="span" bsPrefix="element-three-dots-toggle" onClick={(e) => { e.stopPropagation(); handleToggleDropdown(); }}>
                  <i className="bi bi-three-dots-vertical"></i>
              </Dropdown.Toggle>

              <PortalDropdownMenu>
                  <Dropdown.Menu ref={dropdownRef} onMouseLeave={handleMouseLeave}>
                      <Dropdown.Item onClick={handleModifyClick}>
                          <i className="bi bi-pencil-square" style={{ marginRight: '8px' }}></i> Modify
                      </Dropdown.Item>
                      <Dropdown.Divider />
                      <Dropdown.Item onClick={() => onDelete(element)}>
                          <i className="bi bi-trash" style={{ marginRight: '8px' }}></i> Delete
                      </Dropdown.Item>
                  </Dropdown.Menu>
              </PortalDropdownMenu>
          </Dropdown>
      </div>
  );
};

export default ElementItem;
