import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Dropdown } from 'react-bootstrap';
import './HistoryGroup.css';
import { useMyContext } from '../../context/ProjectProvider';
import { FaTimes } from 'react-icons/fa'; // Asegúrate de tener este ícono importado
import DropdownMenu from './DropdownMenu';

const PortalDropdownMenu = ({ children }) => {
  return ReactDOM.createPortal(
    children,
    document.body
  );
};

const HistoryGroup = ({ isExpanded, toggleVisibility, userName, userEmail }) => {

  const { projects, currentProject, loadNewProject, fetchProjectDetails, modifyOrDeleteProjectAndSave, saveProject, resetSelections } = useMyContext();
  const [hoveredProject, setHoveredProject] = useState(null);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [menuDropdownVisible, setMenuDropdownVisible] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [projectToDelete, setProjectToDelete] = useState(null);
  const [editingProjectId, setEditingProjectId] = useState(null);
  const [newProjectName, setNewProjectName] = useState('');

  useEffect(() => {
    // Fetch the project data if not already loaded
  }, [projects]);

  const handleLogout = () => {
    //console.log('Logout');
  };

  const handleNewProject = () => {
    loadNewProject();
  };

  const handleEditProject = (project) => {
    resetSelections();
    setEditingProjectId(project.conversationId);
    setNewProjectName(project.projectName);
  };

  const handleSaveProjectName = (project) => {
    resetSelections();  // Llamada a resetSelections
    modifyOrDeleteProjectAndSave({ ...project, projectName: newProjectName }, newProjectName);
    setEditingProjectId(null); // Salir del modo de edición después de guardar
  };

  const handleDeleteProject = async () => {
    if (projectToDelete) {
      await modifyOrDeleteProjectAndSave(projectToDelete, null);
      await loadNewProject();
    }
    setShowPopup(false); // Cierra el popup después de eliminar el proyecto
  };

  const handleShowDeletePopup = (project) => {
    setProjectToDelete(project);
    setShowPopup(true);
  };

  const handleCancelClick = () => {
    setShowPopup(false);
    setProjectToDelete(null);
  };

  const filteredProjects = projects.filter(project => project.projectName !== "NewChat!");

  // Ordenar los proyectos por fecha de más reciente a menos reciente
  const sortedProjects = filteredProjects.sort((a, b) => new Date(b.date) - new Date(a.date));

  const handleToggleDropdown = (index) => {
    if (hoveredProject === index && dropdownVisible) {
      setHoveredProject(null);
      setDropdownVisible(false);
    } else {
      setHoveredProject(index);
      setDropdownVisible(true);
    }
  };

  const handleMouseEnter = (index) => {
    if (index !== hoveredProject) {
      setDropdownVisible(false);
    }
    setHoveredProject(index);
  };

  const handleMouseLeave = () => {
    setHoveredProject(null);
    setDropdownVisible(false);
  };

  const handleClick = async (project) => {
    try {
      // Save the current project first
      await saveProject(currentProject, false);
      
      // Fetch project details after saving the project
      await fetchProjectDetails(project.conversationId);
    } catch (error) {
      if (process.env.REACT_APP_NODE_ENV !== 'production') {
        console.error('Error in handleClick:', error);
      }
      
    } finally {
      setHoveredProject(null);
      setDropdownVisible(false); // Ocultar cualquier dropdown cuando se selecciona un proyecto
    }
  };

  const handleToggleMenuDropdown = () => {
    setMenuDropdownVisible(!menuDropdownVisible);
  };

  return (
    <div className={`history-group ${isExpanded ? 'expanded' : 'collapsed'} custom-padding`}>
      <div className="d-flex flex-column align-items-start w-100 h-100">
        <div className={`button-container ${isExpanded ? 'd-flex justify-content-between' : 'd-flex flex-column align-items-center'} w-100 mb-3`}>
          <div className="d-flex align-items-center" style={{ width: '100%' }}>
            <DropdownMenu
              userName={userName}
              userEmail={userEmail}
              handleLogout={handleLogout}
              menuDropdownVisible={menuDropdownVisible}
              handleToggleMenuDropdown={handleToggleMenuDropdown}
              resetSelections={resetSelections}
            />
            <span className={`deeplayered-text ${isExpanded ? '' : 'collapsed'}`} style={{ marginLeft: '10px' }}>Deeplayered</span>
          </div>
        </div>

        <button className="new-project-button" onClick={handleNewProject}>+ New Project</button>
        <div className={`history-content flex-grow-1 ${isExpanded ? '' : 'collapsed-content'}`}>
          <div className="project-group">
            <div className="date-display">Most Recent</div>
            {sortedProjects.map((project, index) => (
              <div 
                key={index} 
                className="project-item-wrapper"
                onMouseEnter={() => handleMouseEnter(index)}
                onMouseLeave={handleMouseLeave}
              >
                <div 
                  className={`project-item ${(hoveredProject === index || currentProject.conversationId === project.conversationId) ? 'hover' : ''}`} 
                  onClick={() => handleClick(project)}
                >
                  {editingProjectId === project.conversationId ? (
                    <input 
                      className="edit-project-name-input"
                      value={newProjectName}
                      onChange={(e) => setNewProjectName(e.target.value)}
                      onBlur={() => handleSaveProjectName(project)}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          handleSaveProjectName(project);
                        }
                      }}
                      autoFocus
                    />
                  ) : (
                    <span className="project-name">{project.projectName}</span>
                  )}
                  {(hoveredProject === index || currentProject.conversationId === project.conversationId) && (
                    <Dropdown className="project-item-dots" show={hoveredProject === index && dropdownVisible} onToggle={() => handleToggleDropdown(index)}>
                      <Dropdown.Toggle as="span" bsPrefix="three-dots-toggle" onClick={(e) => { e.stopPropagation(); handleToggleDropdown(index); }}>
                        <i className="bi bi-three-dots-vertical"></i>
                      </Dropdown.Toggle>

                      <PortalDropdownMenu>
                        <Dropdown.Menu>
                          <Dropdown.Item onClick={(e) => { 
                            e.stopPropagation(); 
                            resetSelections();
                            handleEditProject(project); 
                          }}>
                            <i className="bi bi-pencil-square" style={{ marginRight: '8px' }}></i> Edit name
                          </Dropdown.Item>
                          <Dropdown.Divider />
                          <Dropdown.Item onClick={(e) => { e.stopPropagation(); handleShowDeletePopup(project); }}>
                            <i className="bi bi-trash" style={{ marginRight: '8px' }}></i> Delete
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </PortalDropdownMenu>
                    </Dropdown>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>

        <button
          onClick={toggleVisibility}
          id="429"
          className="btn btn-light collapse-button mt-auto"
        >
          {isExpanded ? <i className="bi bi-arrow-bar-left"></i> : <i className="bi bi-arrow-bar-right"></i>}
        </button>
      </div>

      {showPopup && (
        <div className="popup">
          <div className="popup-content">
            <div className="popup-line">
              Delete the selected items?
              <button className="close-button" onClick={handleCancelClick}>
                <FaTimes />
              </button>
            </div>
            <div className="popup-line">
              Do you want to delete "{projectToDelete?.projectName}"?
            </div>
            <div className="popup-buttons">
              <button className="cancel-button" onClick={handleCancelClick}>Cancel</button>
              <button className="delete-button" onClick={handleDeleteProject}>Delete</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default HistoryGroup;
