import React, { useState, } from 'react';
import MiniHeader from './MiniHeader/MiniHeader';
import Elements from './Elements/Elements';
import Diagram from './Diagram/Diagram';
import GroupFocus from '../GroupFocus/GroupFocus';
import FocusFlowline from './FocusFlowline/FocusFlowline';
import { useMyContext } from '../../context/ProjectProvider';
import './MultimodalGroup.css';

const MultimodalGroup = () => {
  const [elementsVisible, setElementsVisible] = useState(false);
  const [mode, setMode] = useState('create');
  //const [selectedElementForDiagram, setSelectedElementForDiagram] = useState(null);
  const [currentElementIndex, setCurrentElementIndex] = useState(null);
  const [isGroupFocusOpen, setIsGroupFocusOpen] = useState(false);
  const [selectedInstances, setSelectedInstances] = useState([]); 
  //const [selectedFlowLines, setSelectedFlowLines] = useState([]);
  const [isGridActive, setIsGridActive] = useState(false);
  const [selectedFlowline, setSelectedFlowline] = useState(null);
  const [clickPosition, setClickPosition] = useState({ x: 0, y: 0 });

  const { currentProject, setCurrentProject, loading, resetSelections } = useMyContext();

  const handleCreateElement = () => {
    setCurrentElementIndex(null);
    setIsGroupFocusOpen(true);
    setMode('create');
  };

  const handleToggleElements = () => {
    setElementsVisible(prev => !prev);
  };

  const handleSelectElementForDiagram = (element) => {
    //setSelectedElementForDiagram(element);
  };

  const handleDeselectElementForDiagram = () => {
    //setSelectedElementForDiagram(null);
  };

  const handleInstancesSelected = (instanceIds) => {
    setSelectedInstances(instanceIds);
  };

  const handleFlowLinesSelected = (flowlineIds) => {
    //setSelectedFlowLines(flowlineIds);
  };

  const handleToggleGrid = (isActive) => {
    setIsGridActive(isActive);
  };

  const handleFlowlineClick = (flowline, position) => {
    resetSelections(); // Llama a resetSelections cuando se hace clic en el flowline
    setSelectedFlowline(flowline);
    setClickPosition(position);
  };

  const handleSaveFlowlineName = (newName) => {
    const updatedFlowlines = currentProject.flowLines.map((line) =>
      line.id === selectedFlowline.id ? { ...line, name: newName } : line
    );
    setCurrentProject((prev) => ({ ...prev, flowLines: updatedFlowlines }));
    setSelectedFlowline(null);
  };

  return (
    <div className="multimodal-group" onClick={handleDeselectElementForDiagram}>
      {loading && (
        <div className="loader">
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}
      <MiniHeader 
        onCreateElement={handleCreateElement}
        onToggleElements={handleToggleElements}
        elementsVisible={elementsVisible}
        onToggleGrid={handleToggleGrid}
      />
      <div className="elements-diagram-container">
        <Elements 
          elements={currentProject.availableElements}
          elementsVisible={elementsVisible}
          onSelectElementForDiagram={handleSelectElementForDiagram}
          selectedInstances={selectedInstances}
        />
        <Diagram 
          onInstancesSelected={handleInstancesSelected}
          onFlowlinesSelected={handleFlowLinesSelected}
          isGridActive={isGridActive} 
          onFlowlineClick={handleFlowlineClick} 
        />
      </div>
      {isGroupFocusOpen && (
        <GroupFocus
          onClose={() => setIsGroupFocusOpen(false)}
          element={currentElementIndex !== null ? currentProject.elements[currentElementIndex] : null}
          mode={mode}
        />
      )}
      {selectedFlowline && (
        <FocusFlowline
          flowline={selectedFlowline}
          onClose={() => setSelectedFlowline(null)}
          onSave={handleSaveFlowlineName}
          clickPosition={clickPosition} 
        />
      )}
    </div>
  );
};

export default MultimodalGroup;
