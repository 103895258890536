import React, { useEffect, useState } from 'react';
import HomepageHeader from '../../componentsHomePage/HomepageHeader/HomepageHeader';
import HomepageFooter from '../../componentsHomePage/HomepageFooter/HomepageFooter';
import './About.css';
import leoImage from '../../Assets/leo.png';
import ferImage from '../../Assets/fer.png';

function About() {
  const [sectionsVisible, setSectionsVisible] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0);
    
    const sectionsCount = 5; // Número de secciones
    const delay = 1000; // Retraso en milisegundos entre secciones
    const timeouts = [];

    for (let i = 1; i <= sectionsCount; i++) {
      const timeout = setTimeout(() => {
        setSectionsVisible(i);
      }, i * delay);
      timeouts.push(timeout);
    }

    // Limpieza de timeouts si el componente se desmonta
    return () => {
      timeouts.forEach(timeout => clearTimeout(timeout));
    };
  }, []);

  return (
    <div>
      <HomepageHeader />
      <div className="about-content">

        {/* Nuestro Equipo */}
        <section className={`about-section ${sectionsVisible >= 1 ? 'visible' : ''}`}>
          <h2>Meet the Team</h2>
          <p>Our team of chemical engineers brings a passion for programming and artificial intelligence, combining technical expertise to create transformative tools for the industry. Together, we design solutions that embody our commitment to engineering excellence and innovation.</p>

          <div className="team-photos">
            <div className="team-member">
              <img src={leoImage} alt="Miembro del equipo" className="team-img" />
              <p className="team-name">Leonardo Tonazzolli</p>
              <p className="team-role">Partner</p>
            </div>
            <div className="team-member">
              <img src={ferImage} alt="Miembro del equipo" className="team-img" />
              <p className="team-name">Fernando Fischer</p>
              <p className="team-role">Partner</p>
            </div>
          </div>
        </section>
        
        {/* Misión y Visión */}
        <section className={`about-section ${sectionsVisible >= 2 ? 'visible' : ''}`}>
          <h2>Vision</h2>
          <p>To become global leaders in developing artificial intelligence tools for engineers in the industrial sector, enabling solutions that transform and optimize how industries operate and grow.</p>
          
          <h2>Mission</h2>
          <p>To provide a web-based artificial intelligence platform presented as an intuitive chatbot, specially designed for companies that market machinery and industrial products. Our platform allows users to access quick, professional technical advice, enhancing their interaction and experience when purchasing industrial products.</p>
        </section>
        
        {/* Historia de la Empresa */}
        <section className={`about-section ${sectionsVisible >= 3 ? 'visible' : ''}`}>
          <h2>Our Story</h2>
          <p>Founded in 2023, Deeplayered LLC was born from the shared vision of two friends, both chemical engineers, who met during their studies and discovered a mutual passion for artificial intelligence and programming. Driven by a desire to revolutionize the field of engineering, we have developed advanced solutions that help industries achieve new levels of efficiency and precision.</p>
        </section>
        
        {/* Proyección y Futuro */}
        <section className={`about-section ${sectionsVisible >= 4 ? 'visible' : ''}`}>
          <h2>Our Future</h2>
          <p>At Deeplayered, we are driven by an unwavering commitment to innovation and continuous improvement. We strive to expand our solutions to new industrial sectors, tackling complex technical challenges. Our mission is to solve problems and optimize everyday industrial processes, contributing to better final products and, ultimately, improved industry performance.</p>
        </section>
      </div>
      <HomepageFooter />
    </div>
  );
}

export default About;
