import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import './Chat.css'; // Import the Chat-specific CSS
import HistoryGroup from '../../components/HistoryGroup/HistoryGroup';
import MultimodalGroup from '../../components/MultimodalGroup/MultimodalGroup';
import { useMyContext } from '../../context/ProjectProvider';
import SimulatorGroup from '../../components/SimulatorGroup/SimulatorGroup';
import ChatBar from '../../components/ChatBar/ChatBar';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Chat = () => {
  const query = useQuery();
  const ret_id = query.get('id');

  // Access current project and business client status from context
  const { currentProject, 
    setCurrentProject, 
    id, 
    setId,
   } = useMyContext();
  
  // Local state management for Chat component
  const [isExpanded, setIsExpanded] = useState(true);

  // Set id to true if 'id' exists in the query parameter
  useEffect(() => {
    if (ret_id) {
      setId(ret_id);
    } else {
      setId(null);
    }
  }, [ret_id, setId]); // agregado setId, setReactAppNoStreamApi, setReactAppStreamApi por warnings

  // Toggle the visibility of certain elements (used for HistoryGroup)
  const toggleVisibility = () => {
    setIsExpanded(!isExpanded);
  };

  // Add a new element to the current project
  const addElement = (newElement) => {
    setCurrentProject((prevProject) => ({
      ...prevProject,
      elements: [...prevProject.elements, newElement],
      id: id
    }));
  };

  return (
    <div className="Chat">
      <div className="main-content">
        {/* Conditionally render HistoryGroup only if isBusinessClient is false */}
        {id!=="1" && (
          <HistoryGroup 
            isExpanded={isExpanded} 
            toggleVisibility={toggleVisibility}
          />
        )}
        <div className="content">
          <div className="multimodal-group-container">
            <MultimodalGroup 
              elements={currentProject.elements} 
              setElements={(updatedElements) => setCurrentProject((prev) => ({ ...prev, elements: updatedElements }))}
              addElement={addElement}
            />
            <SimulatorGroup />
          </div>
          <ChatBar messages={currentProject.messages} />
        </div>
      </div>
    </div>
  );
};

export default Chat;
