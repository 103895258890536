import React, { useState, useRef, useEffect, useCallback } from 'react';
import SimulatorHeader from './SimulatorHeader/SimulatorHeader';
import SimuList from './SimuList/SimuList';
import './SimulatorGroup.css';

const SimulatorGroup = () => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [width, setWidth] = useState(250); // Ancho inicial
  const [isResizing, setIsResizing] = useState(false);
  const simulatorGroupRef = useRef(null);

  const toggleCollapse = () => {
    setIsCollapsed((prevState) => !prevState);
  };

  const startResizing = (e) => {
    setIsResizing(true);
    e.preventDefault();
  };

  const stopResizing = () => {
    setIsResizing(false);
  };

  const resize = useCallback((e) => {
    if (isResizing) {
      const maxWidth = window.innerWidth * 0.4; // 40% del ancho de la pantalla
      const minWidth = 250; // Ancho mínimo en px
      const newWidth = window.innerWidth - e.clientX;

      // Limitar el ancho entre minWidth y maxWidth
      if (newWidth >= minWidth && newWidth <= maxWidth) {
        setWidth(newWidth);
      } else if (newWidth < minWidth) {
        setWidth(minWidth);
      } else if (newWidth > maxWidth) {
        setWidth(maxWidth);
      }
    }
  },[isResizing]);

  useEffect(() => {
    if (isResizing) {
      document.addEventListener('mousemove', resize);
      document.addEventListener('mouseup', stopResizing);
    } else {
      document.removeEventListener('mousemove', resize);
      document.removeEventListener('mouseup', stopResizing);
    }

    return () => {
      document.removeEventListener('mousemove', resize);
      document.removeEventListener('mouseup', stopResizing);
    };
  }, [isResizing, resize]); //anadido resize por warning

  return (
    <div
      className={`simulator-group ${isCollapsed ? 'collapsed' : ''}`}
      style={{ width: isCollapsed ? '68px' : `${width}px` }}
      ref={simulatorGroupRef}
    >
      {!isCollapsed && (
        <div
          className="resizer"
          onMouseDown={startResizing}
        />
      )}
      <SimulatorHeader isCollapsed={isCollapsed} toggleCollapse={toggleCollapse} />
      {/* Pasar el estado isCollapsed a SimuList */}
      <SimuList isSimulatorGroupCollapsed={isCollapsed} />
    </div>
  );
};

export default SimulatorGroup;
